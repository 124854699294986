import { useThemeProvider } from '@albo-ui/core';
import { useAuth } from '../providers/auth-provider';
import { useStatusShareholdersAndSoleOwners } from './use-shareholders-and-soleowners-status';

export type OnboardinFormTypes =
  | 'LEGAL_REPRESENTATIVE'
  | 'ACCOUNT_CONFIG'
  | 'COMPANY_INFO'
  | 'SHAREHOLDER'
  | 'CARD_REQUEST';

export type QuickWinOnboardinFormTypes =
  | 'QUICK_WIN_LEGAL_REPRESENTATIVE'
  | 'QUICK_WIN_ACCOUNT_CONFIG'
  | 'QUICK_WIN_COMPANY_INFO'
  | 'QUICK_WIN_SHAREHOLDER'
  | 'QUICK_WIN_CARD_REQUEST'
  | 'QUICK_WIN_REAL_OWNER'
  | 'QUICK_WIN_PEP_RELATION';

export const usePmStatusQuickWin = () => {
  const { theme } = useThemeProvider();
  type status = keyof typeof theme.statusCardColors;
  const {
    leadData: { onboardingForms },
  } = useAuth();


  const {
    isCompleteShareholders,
    nextOwners,
    isCompleteSoleOwners,
    sizeSoleOwners,
    sizeShareholders,
    areShareholdersPendingApproval,
    areLegalRepsPendingApproval,
    areRealOwnersPendingApproval
  } = useStatusShareholdersAndSoleOwners();

  const companyInfoForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_COMPANY_INFO'
  );
  const isCompanyInfoPendingApproval = companyInfoForm?.status as string === 'pending-user-approval'
  const companyInfoStatus: status = isCompanyInfoPendingApproval && companyInfoForm?.isCompleted
    ? 'pendinguserapproval'
    : companyInfoForm?.isCompleted
      ? 'done'
      : 'pending';


const legalRepresentativeForms = onboardingForms.filter(
    ({ formType }: any) =>
        formType === 'QUICK_WIN_LEGAL_REPRESENTATIVE' 
)
const legalRepresentativeFormIsCompleted = legalRepresentativeForms?.every((form:any) => form?.isCompleted)

  const legalRepresentativeStatus: status =
    areLegalRepsPendingApproval && legalRepresentativeFormIsCompleted
      ? 'pendinguserapproval'
      : companyInfoStatus === 'pending' && !isCompanyInfoPendingApproval
        ? 'disabled'
        : companyInfoStatus === 'done' &&
        legalRepresentativeForms.length === 0
          ? 'default'
          : legalRepresentativeFormIsCompleted
            ? 'done'
            : 'pending';
  

  const shareholdersStatus: status =
  areShareholdersPendingApproval && isCompleteShareholders 
    ? 'pendinguserapproval'
    :(legalRepresentativeStatus === 'pending' ||
      legalRepresentativeStatus === 'disabled' ||
      legalRepresentativeStatus === 'default') && !areLegalRepsPendingApproval
      ? 'disabled'
      : legalRepresentativeStatus === 'done' && sizeShareholders === 0
        ? 'default'
        : !isCompleteShareholders
          ? 'pending'
          : 'done';

  const soleOwnersStatus: status =
      areRealOwnersPendingApproval  && isCompleteSoleOwners
      ? 'pendinguserapproval'
      : (shareholdersStatus === 'pending' ||
        shareholdersStatus === 'disabled' ||
        shareholdersStatus === 'default') && !areRealOwnersPendingApproval
        ? 'disabled'
        : isCompleteShareholders && !nextOwners
          ? 'done'
          : isCompleteShareholders && sizeSoleOwners === 0
            ? 'default'
            : isCompleteShareholders && isCompleteSoleOwners
              ? 'done'
              : 'pending';




  const shareHolderAndRealoWnersStatus = 
   shareholdersStatus ==='pending' || soleOwnersStatus === 'pending' ? 'pending' 
  : (
    soleOwnersStatus === 'disabled' ?'disabled':shareholdersStatus
  )

  const accountConfigForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_ACCOUNT_CONFIG'
  );

  const accountConfigStatus: status =
      accountConfigForm?.status === 'pending-user-approval'
      ? 'pendinguserapproval'
      : (shareholdersStatus === 'pending' ||
        shareholdersStatus === 'disabled' ||
        shareholdersStatus === 'default'
        ) && !areShareholdersPendingApproval
        ? 'disabled'
        : shareholdersStatus === 'done' &&
          accountConfigForm && !accountConfigForm?.isCompleted
          ? 'default'
          : accountConfigForm?.isCompleted
            ? 'done'
            : 'pending';




  const readyToSend =
    legalRepresentativeStatus === 'done' &&
    shareholdersStatus === 'done' &&
    companyInfoStatus === 'done' &&
    accountConfigStatus === 'done' &&
    soleOwnersStatus === 'done';

  const allFormsIsCompleted = onboardingForms.every((form: any) => form?.isCompleted)
  return {
    legalRepresentativeStatus,
    shareholdersStatus,
    soleOwnersStatus,
    companyInfoStatus,
    accountConfigStatus,
    readyToSend,
    allFormsIsCompleted,
    shareHolderAndRealoWnersStatus
  };
};
