import {
  FlexGrid,
  RoundedIcon,
  useThemeProvider,
  Image,
  Typography,
  Button,
  Alert,
  useAlert,
  Snackbar,
  useSnackbar,
  CodeInput,
  Link,
} from '@albo-ui/core';
import { FaMobileAlt } from 'react-icons/fa';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useTranslator } from '../../providers/translator-provider';

import {  useLocation } from 'react-router';

import { useEffect, useMemo, useState } from 'react';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';

import { LinkExpired } from '../link-expired';
import { useTimers } from '../../providers/use-timer';
import { useUnaffiliatedAuth } from '../../providers/unaffiliated-auth-provider';

const timerName = 'signContract';

export const VerifyUnaffiliatedPhone = () => {
  const { theme } = useThemeProvider();
  const alertProps = useAlert();
  const openAlert = alertProps.openAlert;
  const location = useLocation();
  const search = location.search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  
 
  

  const { openSnackbar, ...snackbarProps } = useSnackbar();
  const {
    validateEmailToken,
    validatePhoneCode,
    phone,
    loading,
    isEmailTokenValidated,
    error,
    lastCodeChangeDate,
    emailToken,
    sendAttempts,
    api
  } = useUnaffiliatedAuth();
  const [code, setCode] = useState('');

  const { addTimer, timers } = useTimers();
  
  
  const [resendingCode, setResendingCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const {
    RESEND_SMS_CODE,
    VALIDATE,
    RESEND_CODE_AVAILABE_IN,
    CODE_ENTERED_IS_INCORRECT,
    UNDERSTOOD,
  } = useTranslator();


  

  useEffect(() => {
    (async () => {
      await validateEmailToken(query.get('token') as string);
    })();
  }, [query, validateEmailToken]);




  useEffect(() => {
    if (isEmailTokenValidated) openAlert();
  }, [isEmailTokenValidated, openAlert]);




  
  const onVerifyCode = async () => {
    await validatePhoneCode(code).then(() => {
      setShowError(true);
    });
  };

  const onCodeInput = (value: string) => {
    setCode(value);
  };

  const resendCode = async () => {
    setResendingCode(true);
    try {
      await api.callService(
        'require-auth-sms-code',
        {
          action: 'LEGAL_REPRESENTATIVE_SIGN_UP',
        },
        { signedUrlToken: emailToken }
      );
    } catch (e) {
      console.log('e', e);
    }

    setResendingCode(false);

    var endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + 2);
    addTimer(timerName, endDate);
  };

  const onSnackBarClose = () => {
    snackbarProps.onClose();
    setShowError(false);
  };

  useEffect(() => {
    if (error === 'INVALID_PHONE_CODE' && showError) openSnackbar();
  }, [error, openSnackbar, showError]);

  useEffect(() => {
    if (error === 'USER_NOT_ALLOWED' || (sendAttempts && sendAttempts > 2)) {
      var endDate = lastCodeChangeDate
        ? new Date(lastCodeChangeDate)
        : new Date();
      endDate.setMinutes(endDate.getMinutes() + 2);
      addTimer(timerName, endDate);
    }
  }, [error, addTimer, lastCodeChangeDate, sendAttempts]);
  
 if (
    (!isEmailTokenValidated && isEmailTokenValidated !== undefined) ||
    error === 'AUTHORIZATION_NOT_ALLOWED'
  )
    return <LinkExpired />;
  

  if (loading || resendingCode)
    return <OnBoardingLoader isLoading={loading || resendingCode} />;

 
  return (
    <div style={{ height: '100vh' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <Alert {...alertProps} description='verificando' type='success' />
      </div>
      <Snackbar
        open={snackbarProps.open}
        message={CODE_ENTERED_IS_INCORRECT}
        action={UNDERSTOOD}
        onClose={onSnackBarClose}
      />
      <FlexGrid
        container
        direction='column'
        padding={8}
        fullWidth
        fullHeight
        style={{ backgroundColor: 'white' }}
      >
        
        <FlexGrid
          item
          container
          alignItems='center'
          direction='column'
          spacing={10}
        >
          <FlexGrid item>
            <Image src={logoPrimarioIndigo} size='163px' />
          </FlexGrid>
          <FlexGrid
            item
            container
            direction='column'
            spacing={4}
            alignItems='center'
            style={{ textAlign: 'center' }}
          >
            <FlexGrid item>
              <RoundedIcon
                icon={<FaMobileAlt size='40px' color={theme.colors.primary} />}
                size='big'
                color='manatee'
              />
            </FlexGrid>
            <FlexGrid
              item
              container
              direction='column'
              spacing={4}
              alignItems='center'
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='h2'>
                  Verifica tu teléfono
                  </Typography>
                </FlexGrid>
                <FlexGrid item style={{ maxWidth: '530px' }}>
                  <Typography variant='paragraph'>
                  Por favor, ingresa el código de 4 dígitos que hemos enviado por SMS al número de teléfono registrado {phone}
                  </Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid
                item
                container
                fullWidth
                direction='column'
                spacing={3}
                style={{ maxWidth: '450px' }}
              >
                <FlexGrid item>
                  <CodeInput
                    onInput={onCodeInput}
                    size={4}
                    error={error === 'INVALID_PHONE_CODE'}
                  />
                </FlexGrid>
                <FlexGrid item>
                  {timerName in timers ? (
                    <>
                      <Typography variant='paragraph' color='lightNeutral' bold>
                        {RESEND_CODE_AVAILABE_IN}:{' '}
                      </Typography>
                      <Typography variant='paragraph' color='paragraph' bold>
                        {timers[timerName].timeLeft.value}
                      </Typography>
                    </>
                  ) : (
                    <Link onClick={resendCode} bold>
                      {RESEND_SMS_CODE}
                    </Link>
                  )}
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item fullWidth style={{ maxWidth: '450px' }}>
                <Button onClick={onVerifyCode} disabled={code.length < 4}>
                  {VALIDATE}
                </Button>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </div>
  );
};
