import { useCallback, useEffect, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { Api } from '../../api';
import useLocalStorage from '../../hooks/use-local-storage';
import { useApi } from '../api-provider';
import { useAuth } from '../auth-provider';
export type ActionType =
  | 'LOGIN'
  | 'PRELEAD'
  | 'SIGN_CONTRACT'
  | 'COMPLETE_LEGAL_REPRESENTATIVE_ACCOUNT';

export type SendPhoneParams = {
  action: ActionType;
  token?: string;
  paramApi?: Api;
};

export const {
  Provider: TwoFactorAuthProvider,
  useProvider: useTwoFactorAuth,
} = makeProvider(() => {
  const [api] = useApi();
  const { loadCredentials } = useAuth();
  const [state, setState] = useState<{
    loading?: boolean | undefined;
    error?: 'INVALID_PHONE_CODE' | 'USER_NOT_ALLOWED' | 'USER_BLOCKED';
    lastCodeChangeDate?: string;
    lastBlockDate?: string;
    eventName?: 'PhoneCodeValidated' | string;
    action?: ActionType;
  }>({});
  const [authJwt] = useLocalStorage('auth_jwt', undefined);

  const sendPhoneCode = useCallback(
    async ({ action, paramApi, token }: SendPhoneParams) => {
      setState((currentState) => ({ ...currentState, action }));

      const sendPhoneCodeResult = await (paramApi || api).callService(
        'require-auth-sms-code',
        {
          action,
        },
        { signedUrlToken: token }
      );

      if (sendPhoneCodeResult.data.info.reason === 'USER_NOT_ALLOWED') {
        setState((currentState) => ({
          ...currentState,
          lastCodeChangeDate: sendPhoneCodeResult.data.info.lastCodeChangeDate,
          lastBlockDate: sendPhoneCodeResult.data.info.lastBlockDate,
          error: sendPhoneCodeResult.data.info.reason,
        }));
      }

      if (sendPhoneCodeResult.data.info.reason === 'USER_BLOCKED') {
        setState((currentState) => ({
          ...currentState,
          lastCodeChangeDate: sendPhoneCodeResult.data.info.lastCodeChangeDate,
          lastBlockDate: sendPhoneCodeResult.data.info.lastBlockDate,
          error: sendPhoneCodeResult.data.info.reason,
        }));
      }

      if (sendPhoneCodeResult.eventName === 'AuthSmsCodeRequired') {
        setState({});
      }

      return sendPhoneCodeResult.data;
    },
    [api]
  );

  const validatePhoneCode = useCallback(
    async (code: string, action: ActionType) => {
      setState((currentState) => ({ ...currentState, loading: true }));
      const validatePhoneCodeResult = await api.callService(
        'validate-phone-code',
        {
          code,
          action,
        }
      );
      if (validatePhoneCodeResult.eventName === 'PhoneCodeValidated') {
        setState({
          eventName: validatePhoneCodeResult.eventName,
          loading: false,
        });
      }

      if (validatePhoneCodeResult.data?.info?.reason === 'USER_BLOCKED') {
        setState((currentState) => ({
          ...currentState,
          lastCodeChangeDate:
            validatePhoneCodeResult.data.info.lastCodeChangeDate,
          lastBlockDate: validatePhoneCodeResult.data.info.lastBlockDate,
          error: validatePhoneCodeResult.data.info.reason,
        }));
      }

      if (
        validatePhoneCodeResult.eventName === 'PhoneCodeNotValidated'
      ) {
        setState((currentState) => ({
          ...currentState,
          loading: false,
          error: 'INVALID_PHONE_CODE',
        }));
      }
      return validatePhoneCodeResult
    },
    [api]
  );

  useEffect(() => {
    if (authJwt && loadCredentials) loadCredentials(authJwt);
  }, [authJwt, loadCredentials]);

  return {
    ...state,
    validatePhoneCode,
    sendPhoneCode,
    api,
  };
});
