import {
  useCheckBox,

  useDialog,
  FlexGrid,
  Typography,
} from '@albo-ui/core';
import { useState } from 'react';
import { useTwoFactorAuth } from '../../../providers/two-factor-auth-provider';
import { useAuth } from '../../../providers/auth-provider';
import { useTranslator } from '../../../providers/translator-provider';
import { getTimeLeft } from '../../../providers/use-timer/helper';
import { InfoDialog } from '../../../components-quick-win/info-dialog';
import { CodeDialog } from '../../../components-quick-win/code-dialog';
import { ContractDocument } from './contract-document';
import { useApi } from '../../../providers/api-provider';
import useLocalStorage from '../../../hooks/use-local-storage';
import { OnBoardingLoader } from '../../../components-quick-win/on-boarding-loader';
import { useHistory } from 'react-router';
import { useSnackbarNotifier } from '../../../hooks/use-snackbar-notifier';


const temporalBlockTime = 10;

export type SignStatusItem = {
  name: string;
  statusText: string;
  status: 'done' | 'pending';
};

export type SignContractProps = {
  name: string;
  signerType: string;
  template: {
    version: string;
    type: string;
  };
  signed?:SignStatusItem
  srcDoc: string;
};

export const ContractForm = ({
  name,
  signerType,
  template,
  srcDoc,
signed
}: SignContractProps) => {
  const checkBoxProps = useCheckBox();
  const dialogProps = useDialog();
  const { error, sendPhoneCode, lastBlockDate } = useTwoFactorAuth();
  const endBlockDate = lastBlockDate ? new Date(lastBlockDate) : new Date();
  endBlockDate.setMinutes(endBlockDate.getMinutes() + temporalBlockTime);
  const { logout } = useAuth();
  const openDialog = dialogProps.openDialog;
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api] = useApi()
  const history = useHistory();
  const {loading:twoFactorLoading}=useTwoFactorAuth()
  const [container] = useLocalStorage<any | undefined>('container', undefined);
  const [showSnackbar] = useSnackbarNotifier();
  const {
    VALIDATE_AND_ACCEPT,
    ACCEPT_FULL_READING_CONTRACT,
    SMS_AUTHENTICATION_REQUIRED,
    I_ACCEPT_I_HAVE_READ_CONTRACT_TEXT,
    TEMPORAL_BLOCK_INFO_DESCRIPTION_FIRST_PART,
    TEMPORAL_BLOCK_INFO_DESCRIPTION_SECOND_PART,
    TEMPORAL_BLOCK,
  } = useTranslator();

  function onSign() {
    sendPhoneCode({ action: 'SIGN_CONTRACT' });
    setShowDialog(true);
    if (error !== 'USER_BLOCKED') openDialog();
  }

  function onTemporalBlockDialogConfirm() {
    setShowDialog(false);
    logout();
  }

  function onCloseCodeDialog() {
    dialogProps.onClose();
    setShowDialog(false);
  }

  const onVerifyCode = async (token: string) => {
    try {
      setLoading(true)
      const { latitude, longitude } = container?.location;
      const result = await api.callService('sign-contract', {
        geoaddress: `${latitude},${longitude}`,
        template: template
      }, { phoneCodeToken: token });
      if (result.eventName === 'UserSigned') {
        showSnackbar('Documento firmado correctamente');
        history.push('/document-signing/sign');
      }else{
        
        throw new Error('Error al firmar el contrato')
      }
    } catch (error) {
      showSnackbar('Error al firmar el contrato. Por favor intentelo nuevamente');
      console.log(error)
    } finally {
      onCloseCodeDialog();
      setLoading(false)
    }

  }


  const minutesLeft = getTimeLeft(endBlockDate).minutesLeft;
  const minutesText = minutesLeft === 1 ? 'minuto' : 'minutos';

  const description = (
    <FlexGrid item>
      <Typography variant='paragraph'>
        {TEMPORAL_BLOCK_INFO_DESCRIPTION_FIRST_PART}{' '}
        <span className='f-bold'>
          {minutesLeft < 1 ? 1 : minutesLeft} {minutesText}
        </span>{' '}
        {TEMPORAL_BLOCK_INFO_DESCRIPTION_SECOND_PART}{' '}
      </Typography>
    </FlexGrid>
  );



  if (loading || twoFactorLoading)
    return (
      <OnBoardingLoader
        isLoading={loading|| twoFactorLoading}
      />
    );


  return (
    <>
      <InfoDialog
        open={error === 'USER_BLOCKED' && showDialog}
        onClose={onTemporalBlockDialogConfirm}
        title={TEMPORAL_BLOCK}
        description={description}
        help
      />
      <CodeDialog
        open={dialogProps.open}
        onClose={onCloseCodeDialog}
        onVerify={onVerifyCode}
        action='SIGN_CONTRACT'
      />
      <ContractDocument
        {...checkBoxProps}
        name={name}
        signerType={signerType}
        signCardDescription={SMS_AUTHENTICATION_REQUIRED}
        checkBoxText={I_ACCEPT_I_HAVE_READ_CONTRACT_TEXT}
        signButton={{
          text: VALIDATE_AND_ACCEPT,
          description: ACCEPT_FULL_READING_CONTRACT,
        }}
        onSignButtonClick={onSign}
        srcDoc={srcDoc}
        isSigned={signed?.status === 'done'}
        dateSigned={signed?.statusText}
      />
    </>
  );
};
