import { FormItemProps } from "@albo-ui/core";
import { useCallback, useEffect, useState } from "react";
import { makeProvider } from "react-provider-maker";
import {
  OnboardinFormTypes,
  QuickWinOnboardinFormTypes,
} from "../../hooks/use-onboarding-form";
import { useUserRole } from "../../hooks/use-user-role";
import { useApi } from "../api-provider";
import { useAuth } from "../auth-provider";

//Dado que no tiene ninguna dependecia, se puede crear fuera del hook y no se vuelve a crear en cada render
const fieldExists = (
  resultList: any[],
  formItem: FormItemProps<any>
): any | null => {
  const field = resultList?.find((definition) => {
    return definition.name === formItem.name;
  });
  return field || formItem;
};


const getSectionsCompleted = (sectionReferences:any,uuidForm?:string) => {
    const result:any[]=[]
    sectionReferences?.sectionStatus?.forEach((section:any)=>{
        section?.subsections?.forEach((subSection:any)=>{
        if(subSection?.status === "completed"){
            if(!uuidForm){
                result.push(subSection)
            }else if(subSection?.formUuid === uuidForm){
                result.push(subSection)
            }
        }
        })
    })
    return result
}
const getSectionReference = (sectionCompleted:any,sectionReferences:any) => {
    const sections = sectionCompleted.map((section:any)=>{
        const sectionName = section.sectionName
        const sectionReference = sectionReferences?.sectionReferences?.find((s:any)=>{return s.sectionName === sectionName})
        return sectionReference
    })
    return sections
}
const getLockedSectionsByFormType = (sectionReferences:any, formType:string) => {
    const lockedSections:any[] = []
    for (const section of sectionReferences) {
        for (const attribute of section.attributes)
         {
            if (attribute.formType === formType ) {
                lockedSections.push(attribute)
            }
        }
    }
    return lockedSections
}

const calculateItemDisabled = (lockedSections:any,fieldName:string) => {
    for (const section of lockedSections) {
        const include = section.lockValues?.includes(fieldName)
        if (section.lockType === "include" && include) {
            return true
        }
        if (section.lockType === "exclude" && !include) {
            return true
        }
    }
    return false
}


const filterValidators = (validator: any) =>
  validator.name !== "isEmpty" &&
  validator.name !== "phoneNumberValidations" &&
  validator.name !== "doNotHaveMinLength";

export const { Provider: FormsProvider, useProvider: useFormsFields } =
  makeProvider(() => {
    const [formDefinition, setFormDefinition] = useState<any>(new Map());
    const [sectionReferences, setSectionReference] = useState<any>(new Map());
    const { isOpsAnalyst } = useUserRole();
    const [api] = useApi();
    const { user } = useAuth();

    useEffect(() => {
      if (user && user?.leadUuid) {
        api.callService("get-lead-section-reference", {
            lead_uuid: user?.leadUuid,
          })
          .then((result) => {
            setSectionReference(result?.data?.sections);
          });
      }
    }, [user, api]);

    const updateFormDefinition = useCallback(
      (k: any, v: any) => {
        setFormDefinition(new Map(formDefinition.set(k, v)));
      },
      [formDefinition]
    );

    const formFields = useCallback(
      (
        formName: QuickWinOnboardinFormTypes | OnboardinFormTypes,
        items: FormItemProps<any>[],
        uuidForm?: string
      ) => {
       
        
        const sectionsCompleted = getSectionsCompleted(sectionReferences,uuidForm);
        const sectionsReference = getSectionReference(sectionsCompleted,sectionReferences)
        const lockedSections = getLockedSectionsByFormType(sectionsReference,formName)
        const isFormDisabled = lockedSections.some((section:any)=>section.lockType === "all")
        
        if (!isOpsAnalyst) {
          return items.map((item: FormItemProps<any>) => {
            const isItemDisabled = calculateItemDisabled(lockedSections,item?.name)
            return {
              ...item,
              disabled: isFormDisabled || isItemDisabled || item?.disabled,
            };
          });
        }

        const result = formDefinition.get(formName);

        const formItems = items.map((item: FormItemProps<any>) => {
          const field = fieldExists(result, item);
          const isItemDisabled = calculateItemDisabled(lockedSections,field?.name)
          return {
            ...item,
            disabled: isFormDisabled || isItemDisabled || field?.disabled,
            required: false,
            validators: field?.validators?.filter(filterValidators) || [],
          };
        });

        return formItems;
      },
      [isOpsAnalyst, formDefinition,sectionReferences]
    );

    const findFields = useCallback(
      async (formName: QuickWinOnboardinFormTypes | OnboardinFormTypes) => {
        if (!isOpsAnalyst) {
          return;
        }

        if (formDefinition[formName]) {
          return;
        }

        const result = await api.callService("get-form-definition", {
          formType: formName,
          role: "OPS_ANALYST",
        });

        updateFormDefinition(formName, result.data.fieldsDefinition);
      },
      [isOpsAnalyst, formDefinition, api, updateFormDefinition]
    );

    return { formFields, findFields };
  });
