import {
  CheckBox,
  FlexGrid,
  Form,
  FormItemProps,
  RadialButton,
  Typography,
  useCheckBox,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { useAddressInputs } from '../../common';
import { FormItems } from '../form-items';
import { useCatalog } from '../../hooks/use-catalog';
import { useValidators } from '../../utils/validators';
import { useDefaultValidations } from '../../utils/default-validations';
import { snakeToCamelCase } from '../../utils/convert';
import { BottomActions } from '../bottom-actions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { usePmStatusQuickWin } from '../../hooks/use-pm-status-quick-win';
import { canadaCountryCode, mainCountry, mainCountryStr, unitedStatesCountryCode } from '../../const';
import useLocalStorage from '../../hooks/use-local-storage';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';

export const CompanyInfoForm = () => {
  const [addressContext] = useLocalStorage<any | undefined>('moreInfo', undefined);
  const [container] = useLocalStorage<any | undefined>('container', undefined);

  const formProps = useOnboardingForm({
    defaultParams: {
      phone: { phoneCode: '+52', phoneCountry: '484', phoneCodeDesc: 'Mexico' },
      address: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
      notificationAddress: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
      isPepRelated: false
    },
    onboardingFormType: 'QUICK_WIN_COMPANY_INFO',
    nextSectionUrl: 'legal-representative',
    singleton: true,
  });
  const { theme } = useThemeProvider();
  const [economicActivities] = useCatalog('EconomicActivities');
  const [countries] = useCatalog('Countries');
  const validators = useValidators();
  const defaultValidations = useDefaultValidations();
  const pmStatus = usePmStatusQuickWin();
  const homeCBS = useOnHomeCBS();
  const checkBoxProps = useCheckBox();
  const checkBoxValue = checkBoxProps.value;
  const { isOpsAnalyst } = useUserRole();

  const hasCheckedAddress = useRef<boolean>(false);
  const toggleCheckBox = checkBoxProps.onInput;
  const address = JSON.stringify(formProps.form.data.address);
  const physicalAddress = JSON.stringify(
    formProps.form.data.notificationAddress
  );

  const addressesAreTheSame = address === physicalAddress;

  const setFormData = formProps.form.setData;
  const formData = formProps.form.data;
  const setShowDialog = homeCBS.setShowDialog;
  const companyInfoStatus = pmStatus.companyInfoStatus;
  const isPepRelated = formData.isPepRelated;
  const addressJson = formData.address;
  const countryForm = addressJson.countryDesc?.toLowerCase();
  const stateForm = addressJson.stateDesc?.toLowerCase();
  const countryContext = addressContext?.country?.toLowerCase();
  const stateContext = addressContext?.state?.toLowerCase();

  const { setPropByName } = formProps.form;
  const { formFields, findFields } = useFormsFields();


  const [radiosPep, setRadiosPep] = useState([{ text: 'Sí', value: isPepRelated }, { text: 'No', value: !isPepRelated }]);
  const [showReason, setShowReason] = useState(false);

  const coutriesSelect = countries.map((item: any) => ({
    name: item.value,
    value: item.key,
  }));
  const economicActivitiesSelect = economicActivities.map((item: any) => ({
    name: item.value,
    value: item.value,
  }));

  const {
    BUSINESS_DATA,
    ALL_INFO_IS_CONFIDENTIAL,
    BUSINESS_EMAIL,
    BUSINESS_PHONE,
    REQUIRED_FIELD,
    RFC_WITH_HOMOCLAVE,
    TWELVE_CHARACTER_ALPHANUMERIC_KEY,
    COMERCIAL_NAME,
    COMERCIAL_NAME_HELP_DESCRIPTION,
    BUSINESS_PHONE_HELP_DESCRIPTION,
    WEBSITE_OR_SOCIAL_NETWORK,
    ADDRESS_VOUCHER,
    ADDRESS_VOUCHER_HELP_DESCRIPTION,
    FISCAL_ADDRESS,
    E_SIGN,
    E_SIGN_HELP_DESCRIPTION,
    BUSINESS_RFC_CEDULE,
    BUSINESS_RFC_CEDULE_HELP_DESCRIPTION,
    SOCIAL_REASON,
    SOCIAL_REASON_HELP_DESCRIPTION,
    SERVICES_OFFERED,
    COUNTRY_OF_CONSTITUTION,
    CERTIFICATE_CONSTITUTIVE,
    DATE_OF_CONSTITUTION,
    FISCAL_ADDRESS_DESCRIPTION,
    IS_THE_SAME_AS_FISCAL_ADDRESS,
    DATE_FORMAT,
    RELATIONSHIP_WITH_PEP_QUESTION,
    RELATIONSHIP_WITH_PEP_DESCRIPTION,
    ADDRESS_BUSINESS,
    REASON_ACCOUNT,
    DENOMINATION,
    ECONOMIC_ACTIVITY,
    ECONOMIC_ACTIVITY_HELP_DESCRIPTION,
    CONTINUE,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS,
    CERTIFICATE_CONSTITUTIVE_HELP_DESCRIPTION
  } = useTranslator();

  useEffect(() => {
    findFields('QUICK_WIN_COMPANY_INFO');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  })

  const businessDataInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_INFO', [
    {
      placeholder: COMERCIAL_NAME,
      name: 'comercialName',
      type: 'input',
      required: true,
      ...defaultValidations.comercialNameValidations(),
      help: {
        description: COMERCIAL_NAME_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: `${DENOMINATION} o ${SOCIAL_REASON}`,
      name: 'socialReason',
      required: true,
      type: 'input',
      ...defaultValidations.businessNameValidations(),
      help: {
        description: SOCIAL_REASON_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'taxid',
      type: 'input',
      required: true,
      toUpperCase: true,
      ...defaultValidations.rfcWithHomoclaveValidations(),
      help: {
        description: TWELVE_CHARACTER_ALPHANUMERIC_KEY,
      },
    },
    {
      placeholder: BUSINESS_RFC_CEDULE,
      name: 'taxidFile',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: BUSINESS_RFC_CEDULE_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: E_SIGN,
      name: 'cerFile',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: E_SIGN_HELP_DESCRIPTION,
      },
      fileExtensions: ['.pdf', '.cer', '.key', '.req', '.jpg', '.zip', '.rar', '.jpeg', '.docx', '.png']
    },
    {
      placeholder: SERVICES_OFFERED,
      name: 'companyActivities',
      type: 'input',
      required: true,
      ...defaultValidations.servicesOfferedValidations(),
    },
    {
      placeholder: COUNTRY_OF_CONSTITUTION,
      name: 'constitutionCountry',
      type: 'select',
      required: true,
      items: coutriesSelect,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: `${DATE_OF_CONSTITUTION} ${DATE_FORMAT}`,
      name: 'constitutionDate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [
        validators.isEmpty,
        validators.notValidDateFormat, 
        validators.dateIsOverOneHundredYears
      ],
    },
    {
      placeholder: CERTIFICATE_CONSTITUTIVE,
      name: 'constitutiveActFile',
      type: 'fileInput',
      required: true,
      help:{
        description: CERTIFICATE_CONSTITUTIVE_HELP_DESCRIPTION,
      },
      validators: [validators.isEmpty],
    },
    {
      placeholder: ECONOMIC_ACTIVITY,
      name: 'economicActivity',
      type: 'select',
      items: economicActivitiesSelect,
      search: true,
      autocomplete: true,
      validators: [],
      help: {
        description: ECONOMIC_ACTIVITY_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: `${ADDRESS_VOUCHER} reciente`,
      name: 'proofOfAddress',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: ADDRESS_VOUCHER_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: WEBSITE_OR_SOCIAL_NETWORK,
      name: 'website',
      type: 'input',
      pattern: 'default',
      ...defaultValidations.websiteOrSocialNetworkValidations(),
    },
    {
      placeholder: BUSINESS_EMAIL,
      name: 'email',
      type: 'input',
      required: true,
      ...defaultValidations.emailValidations(),
    },
    {
      placeholder: BUSINESS_PHONE,
      name: 'phone',
      type: 'phone',
      required: true,
      ...defaultValidations.phoneNumberValidations(),
      help: {
        description: BUSINESS_PHONE_HELP_DESCRIPTION,
      },
    },
  ]);

  const fiscalAddress: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_INFO', useAddressInputs({
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.address.zip,
    formData: formProps.form.data,
    filterCountries: [mainCountryStr, canadaCountryCode, unitedStatesCountryCode]
  }));

  const notificationAddress: FormItemProps<string>[] =  formFields('QUICK_WIN_COMPANY_INFO',useAddressInputs({
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.notificationAddress.zip,
    propName: 'notificationAddress',
    formData: formProps.form.data,
    disabled: checkBoxValue,
  }));

  const reasonAccount: FormItemProps<string>[] =  formFields('QUICK_WIN_COMPANY_INFO',[{
    placeholder: REASON_ACCOUNT,
    name: 'reasonAccount',
    type: 'input',
    required: true,
    validators: [validators.isEmpty],
  }]);

  const setPep = (index: number) => {
    radiosPep.map((item, i) => item.value = (i === index));
    setRadiosPep([...radiosPep]);
    setPropByName('isPepRelated', index === 0);
  };

  useEffect(() => {
    if (checkBoxValue) {
      setFormData((currentData: any) => {
        return {
          ...currentData,
          notificationAddress: {
            ...currentData.address,
          },
        };
      });
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        notificationAddress: {
          country: mainCountry,
          countryDesc: 'Mexico',
        },
      }));
    }
  }, [checkBoxValue, setFormData]);

  useEffect(() => {
    if (
      addressesAreTheSame &&
      !hasCheckedAddress.current &&
      address !== `{"country":"${mainCountry}","countryDesc":"Mexico"}`
    ) {
      toggleCheckBox();
      hasCheckedAddress.current = true;
    } else if (
      address !== `{"country":"${mainCountry}","countryDesc":"Mexico"}`
    ) {
      hasCheckedAddress.current = true;
    }
  }, [addressesAreTheSame, toggleCheckBox, address]);

  const setErrors = formProps.form.setErrors;

  const removeReasonAccount = useCallback(() => {
    setFormData((currentData: any) => {
      var newData = { ...currentData };
      delete currentData.reasonAccount;
      return newData;
    });
    setErrors((currentErrors: any) => ({
      ...currentErrors,
      reasonAccount: undefined,
    }));
  }, [setFormData, setErrors]);

  useEffect(() => {
    const flagCountry = countryForm !== countryContext;
    const flagState = stateContext !== stateForm;
    const result = flagCountry || flagState;
    setShowReason(result);

    if (!result) {
      removeReasonAccount();
    }
  }, [addressContext, countryForm, stateForm, countryContext, stateContext, removeReasonAccount]);

  useEffect(() => {
    setShowDialog(companyInfoStatus !== 'done');
  }, [setShowDialog, companyInfoStatus]);

  useEffect(() => {
    if (!formData.geolocation && addressContext && container) {

      const { latitude, longitude } = container?.location;
      //Para asegurarme que sea string
      const geolocation = {
        latitude: `${latitude}`,
        longitude: `${longitude}`,
        moreInfo: snakeToCamelCase({ ...addressContext })
      };
      setPropByName("geolocation", geolocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressContext, setPropByName, formData]);

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  if (formProps.saving || formProps.movingForward || formProps.loadingForm)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : formProps.moveForward}>
      <FlexGrid
        container
        fullHeight
        fullWidth
        direction='column'
        spacing={3}
        style={{ paddingBottom: '100px' }}
      >
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{BUSINESS_DATA}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{ALL_INFO_IS_CONFIDENTIAL}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='small' color='neutral' bold>
            <span style={{ color: theme.colors.required }}>*</span>{' '}
            {REQUIRED_FIELD}
          </Typography>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={businessDataInputs} />
        </FlexGrid>
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='smallTitle'>{RELATIONSHIP_WITH_PEP_QUESTION}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{RELATIONSHIP_WITH_PEP_DESCRIPTION}</Typography>
          </FlexGrid>
          <FlexGrid item container direction='row' spacing={2}>
            {radiosPep.map(({ value, text }, index) => (
              <FlexGrid item key={index}>
                <RadialButton
                  key={`pep-radio-${index}`}
                  value={value}
                  onInput={() => setPep(index)}
                  text={text}
                />
              </FlexGrid>
            ))}
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='smallTitle'>{ADDRESS_BUSINESS}</Typography>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={fiscalAddress} />
        </FlexGrid>
        {showReason && <FlexGrid item container fullWidth>
          <FormItems inputs={reasonAccount} />
        </FlexGrid>}
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='smallTitle'>{FISCAL_ADDRESS}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{FISCAL_ADDRESS_DESCRIPTION}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <CheckBox
            onInput={checkBoxProps.onInput}
            value={checkBoxValue}
            text={IS_THE_SAME_AS_FISCAL_ADDRESS}
          />
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={notificationAddress} />
        </FlexGrid>
      </FlexGrid>
      <DialogConfirm />
      <BottomActions
        saveProgressButton={pmStatus.companyInfoStatus !== 'done' && !isOpsAnalyst}
        continueButtonText={isOpsAnalyst ? UPDATE : CONTINUE}
        saveButtonText={SAVE_PROGRESS}
        continueButton
        onSaveProgress={formProps.saveForm}
        onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
      />
    </Form>
  );
};
