import {

    FlexGrid,
    MenuItem,
    Typography,
    useDialog,
    useThemeProvider,
} from '@albo-ui/core';

import { useHistory, useRouteMatch } from 'react-router';
import { ICONS_AGREEMENTS, useAgreementsToSign } from './hooks/use-agreements-to-sign';
import { InfoDialog } from '../../../components-quick-win/info-dialog';
import { useEffect } from 'react';

import logo from "../../../images/Recibimos-tu-solicitud.png"

const TitleDialog = () => {
    return (
        <FlexGrid
            item
            container
            direction='column'
            fullWidth
            spacing={2}
            alignItems='center'
            justify='center'
            fullHeight
        >
            <FlexGrid item>
                <img src={logo} alt="albo" style={{ width: '150px' }} />
            </FlexGrid>
            <FlexGrid item>
                <Typography variant='smallTitle'>
                    ¡Solicitud creada con éxito!
                </Typography>
            </FlexGrid>

        </FlexGrid>
    )

}


export const Home = () => {
    const { theme } = useThemeProvider();
    const { items, allAgreementsIsSigned } = useAgreementsToSign()
    const history = useHistory();
    const { url } = useRouteMatch();
    const { open, onClose, openDialog } = useDialog();
    function onCardClick(route: string) {
        const urlHistory = url.endsWith('/') ? url.slice(0, -1) : url
        history.push(`${urlHistory}/${route}`);
    }

    useEffect(() => {
        if (allAgreementsIsSigned) {
            openDialog()
        }
    }, [allAgreementsIsSigned, openDialog]);


    return (

        <>
            <InfoDialog
                open={open}
                onClose={onClose}
                title={<TitleDialog />}
                description={
                    <>
                        <p>
                            Ya tenemos todo listo para crear tu cuenta albo Empresas.
                        </p>
                        <p>
                            Estamos revisando y ajustando los últimos detalles. Te notificaremos por correo electrónico tan pronto como  todo esté listo para que empieces a disfrutar de todos los beneficios de albo Empresas
                        </p>
                    </>

                }
                buttonText='Entendido'
            />

            <FlexGrid
                container
                fullHeight
                fullWidth
                direction='column'
                alignItems='center'
                padding={4}
                spacing={4}
                style={{
                    overflow: "hidden", overflowY: 'auto', padding: 0,
                    width: 'calc(100vw - 70px)'
                }}
            >
                <FlexGrid item container direction='column' fullHeight style={{ width: "90%" }}>
                    <FlexGrid
                        item
                        container
                        direction='column'
                        fullWidth
                        spacing={1}
                        fullHeight
                        style={{ paddingTop: theme.spacesPx[4] }}
                    >
                        <FlexGrid item>
                            <Typography variant='smallTitle'>
                                Realiza la firma de tu contrato
                            </Typography>
                        </FlexGrid>
                        <FlexGrid item>
                            <Typography variant='small'>Para concluir con tu proceso es necesario que firmes los siguientes documentos</Typography>
                        </FlexGrid>



                        <FlexGrid item container direction='column' spacing={2} style={{ marginTop: theme.spacesPx[4] }} >
                            {items.map(({ icon, title, description, url, status }, index) => (
                                <FlexGrid item key={index}>
                                    <MenuItem
                                        key={index}
                                        title={title}
                                        description={description}
                                        icon={ICONS_AGREEMENTS[icon as keyof typeof ICONS_AGREEMENTS]}
                                        status={status as keyof typeof theme.statusCardColors}
                                        onClick={() => onCardClick(url)}
                                    />
                                </FlexGrid>
                            ))}
                        </FlexGrid>
                    </FlexGrid>
                </FlexGrid>
            </FlexGrid>
        </>
    );
};
