export const adviceOfPrivacyUrl = 'https://www.albo.com/cuenta_empresa/aviso_privacidad';
export const landingPageUrl = 'https://www.albo.com/cuenta_empresa';
export const alboForBusinessUrl = 'https://afb-web.albo.live/login';
export const mainCountryStr =  "484"
// eslint-disable-next-line no-new-wrappers
export const mainCountry:any =  new String(mainCountryStr);
mainCountry._def = true
export const canadaCountryCode = '124'
export const unitedStatesCountryCode = '840'
export const cookieUsePolicyUrl = 'https://www.albo.com/cuenta_empresa/politica_cookies'
