import { useCallback, useEffect, useState } from "react";
import { iterateShareholders } from "../components-quick-win/shareholders/shareholders-owners";
import { useAuth } from "../providers/auth-provider";
import { useQuery } from "./user-query";

export const useStatusShareholdersAndSoleOwners = () => {

    const [isCompleteShareholders, setIsCompleteShareholders] = useState(false);
    const [isCompleteSoleOwners, setIsCompleteSoleOwners] = useState(false);
    const [areShareholdersPendingApproval, setShareholdersPendingApproval] = useState(false);
    const [areLegalRepsPendingApproval, setLegalRepsPendingApproval] = useState(false);
    const [nextOwners, setNextOwners] = useState(true);
    const [showDeclaratory, setShowDeclaratory] = useState(false);
    const [showOwnerCheck, setShowOwnerCheck] = useState(false);
    const [showDocumentsPFAE, setShowDocumentsPFAE] = useState(false);

    const {
        leadData: { onboardingForms },
        reloadProfile
    } = useAuth();

    const shareholderFormArray = onboardingForms.filter(
        ({ formType }: any) =>
            formType === 'QUICK_WIN_SHAREHOLDER'
    )
    const sizeShareholders = shareholderFormArray?.length;
    const shareholdersPendingApproval = onboardingForms.filter(
        ({ formType, status }: any) =>
            formType === 'QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER' && status === 'pending-user-approval'
    ).map(({ uuid }: any) => uuid);

    const shareholdersUuids = onboardingForms.filter(
        ({ formType, isCompleted }: any) =>
            formType === 'QUICK_WIN_SHAREHOLDER' && isCompleted
    ).map(({ uuid }: any) => uuid);

    const sizeSoleOwners = onboardingForms.filter(
        ({ formType }: any) => formType === 'QUICK_WIN_REAL_OWNER'
    )?.length;

    const soleOwnersUuids = onboardingForms.filter(
        ({ formType, isCompleted }: any) =>
            formType === 'QUICK_WIN_REAL_OWNER' && isCompleted
    ).map(({ uuid }: any) => uuid);

    const realOwnersPendingApproval = onboardingForms.filter(
        ({ formType, status }: any) =>
            formType === 'QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER' && status === 'pending-user-approval'
    ).map(({ uuid }: any) => uuid);

    const legalRepresentativesPendingApproval = onboardingForms.filter(
        ({ formType, status }: any) =>
            formType === 'QUICK_WIN_LEGAL_REPRESENTATIVE' && status === 'pending-user-approval'
    ).map(({ uuid }: any) => uuid);

    const [queryFormData] = useQuery({
        name: 'get-onboarding-forms-by-uuids',
        params: { onboardingFormsUuids: shareholdersUuids },
    },
        [onboardingForms]);

    /* 
        Prioridades
        1 - PF, >=25% --- Flujo INE
        2 - PM, >=25%, cotiza bolsa --- Flujo cuenta
        3 - [<25%],[PM,>=25%, no cotiza en bolsa] --- Flujo declaratoria
    */
    const checkDataShareholders = useCallback(() => {
        if (queryFormData && queryFormData?.onboardingForms.length !== 0) {
            const { onboardingForms } = queryFormData;
            const prioritys = iterateShareholders(onboardingForms, true);
            for (var x = 0; x < prioritys.length; x++) {
                if (prioritys[x]) {
                    setFlags(x + 1);
                    break;
                }
            }
        }

    }, [queryFormData]);

    const setFlags = (priority: number) => {
        switch (priority) {
            case 1:
                setShowDocumentsPFAE(true);
                setShowOwnerCheck(true);
                break;
            case 2:
                setNextOwners(false);
                break;
            case 3:
                setShowDeclaratory(true);
                setShowOwnerCheck(true);
                break;
        }
    }

    useEffect(() => {
        if (sizeShareholders > 0 && sizeShareholders === shareholdersUuids?.length) {
            setIsCompleteShareholders(true);
        }
    }, [shareholdersUuids, sizeShareholders]);

    useEffect(() => {
        if (isCompleteShareholders && nextOwners && sizeSoleOwners === soleOwnersUuids?.length) {
            setIsCompleteSoleOwners(true);
        }
    }, [isCompleteShareholders, nextOwners, sizeSoleOwners, soleOwnersUuids]);

    useEffect(() => {
        if (shareholdersPendingApproval.length > 0) {
            setShareholdersPendingApproval(true);
        }
    }, [shareholdersPendingApproval]);

    useEffect(() => {
        if (legalRepresentativesPendingApproval.length > 0) {
            setLegalRepsPendingApproval(true);
        }
    }, [legalRepresentativesPendingApproval]);

    useEffect(() => {
        checkDataShareholders();
    }, [checkDataShareholders]);

    return {
        isCompleteShareholders,
        isCompleteSoleOwners,
        areShareholdersPendingApproval,
        areRealOwnersPendingApproval: realOwnersPendingApproval.length > 0,
        areLegalRepsPendingApproval,
        sizeShareholders,
        sizeSoleOwners,
        showDeclaratory,
        showDocumentsPFAE,
        showOwnerCheck,
        nextOwners,
        reloadProfile
    }
}