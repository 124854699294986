import {
  ActionCard,
  FlexGrid,
  Link,
  RadialButton,
  Skeleton,
  Typography,
  useDialog,
  useDrawer,
  useThemeProvider,
} from '@albo-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import ConfirmationDialog from '../../components/confirm-dialog';
import { useSnackbarNotifier } from '../../hooks/use-snackbar-notifier';
import { useQuery } from '../../hooks/user-query';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useTranslator } from '../../providers/translator-provider';
import { BottomActions } from '../bottom-actions';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { InfoDialog } from '../info-dialog';
import { OnBoardingLoader } from '../on-boarding-loader';
import { AddPhysicalShareholder } from './add-physical-shareholder';
import { MoralShareholder } from './moral-shareholder';


const EDIT_STEPS = {
  NO_EDIT: 'NO_EDIT',
  SELECT_CHECKBOX: 'SELECT_CHECKBOX',
  COMPLETE_FORM: 'COMPLETE_FORM',

}

export const Shareholders = () => {
  const {
    SHAREHOLDERS,
    ADD_SHAREHOLDER,
    ADD_ALL_YOUR_SHAREHOLDERS_OF_YOUR_COMPANY,
    SUCCESS_SAVING_SHAREHOLDER,
    SUCCESS_UPLOADING_INFO,
    YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER,
    YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER_DESCRIPTION,
  } = useTranslator();
  const { theme } = useThemeProvider();
  const shareholderDrawerProps = useDrawer();
  const moralShareholderDrawerProps = useDrawer();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [api] = useApi();
  const [uuidUpdate, setUuidUpdate] = useState<undefined | string>(undefined);
  const [updatePercentage, setUpdatePercentage] = useState<number | undefined>(
    undefined
  );
  const [uuidDelete, setUuidDelete] = useState<undefined | string>(undefined);

  const [editMode, setEditMode] = useState<string>(EDIT_STEPS.NO_EDIT);
  const [editUuidsRealOwner, setEditUuidsRealOwner] = useState<any>({});


  const [show, close] = useSnackbarNotifier();
  const deleteDialogProps = useDialog();
  const {
    open: infoDialogIsOpen,
    openDialog: openInfoDialog,
    onClose: closeInfoDialog,
  } = useDialog();

  const {
    open: infoDialogRealOwnerIsOpen,
    openDialog: openInfoDialogRealOwner,
    onClose: closeInfoDialogRealOwner,
  } = useDialog();

  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(false);
  const {
    leadData: { onboardingForms },
  } = useAuth();
  const { reloadingProfile } = useAuth();

  const shareholdersUuids = useMemo(
    () =>
      onboardingForms
        .filter(({ formType }: any) => formType === 'QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER')
        .map(({ uuid }: any) => uuid),
    [onboardingForms]
  );

  const [queryFormData, loadingForm, reloadForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: shareholdersUuids,
      },
    },
    [shareholdersUuids]
  );

  const shareholderPF:
    | {
      name: string;
      percentage: string;
      type: string;
      beListed: boolean;
      uuid: string;
      status: string;
      isRealOwner: boolean
    }[]
    | undefined = queryFormData
      ? queryFormData.onboardingForms.map((data: any) => ({
        name: data.formData.general.gname,
        uuid: data.uuid,
        percentage: data.formData.general.percentageShares,
        type: data.formData.general.type,
        beListed: data.formData.general.stockMarketListing,
        status: data.isCompleted ? 'done' : 'pending',
        isRealOwner: data.formData.general?.type === 'fisica' && (data.formData.isRealOwner==="yes" || data.formData.general.percentageShares >= 25)
      }))
      : undefined;

  const shareholderPercentageSum = shareholderPF
    ? shareholderPF.reduce(
      (total, { percentage }) => total + parseInt(percentage),
      0
    )
    : 0;
  const existsRealOwner = shareholderPF?.some((sh) => sh.isRealOwner);
  const allIsPM = shareholderPF?.every((sh) => sh.type === 'moral');

  const onAddShareholder = () => {
    shareholderDrawerProps.openDrawer();
    setUuidUpdate(undefined);
    setUpdatePercentage(undefined);
    close('');
  };

  function onBack() {
    if (editMode === EDIT_STEPS.SELECT_CHECKBOX) {
      setEditMode(EDIT_STEPS.NO_EDIT);
      return;
    }
    if (editMode === EDIT_STEPS.COMPLETE_FORM) {
      setEditMode(EDIT_STEPS.SELECT_CHECKBOX);
      return;
    }
    history.push('legal-representative');
  }





  function onContinue() {
    if (editMode === EDIT_STEPS.SELECT_CHECKBOX) {
      setEditMode(EDIT_STEPS.COMPLETE_FORM);
      return;
    }

    if (!existsRealOwner) {
      openInfoDialogRealOwner();
      return;
    }

    if (shareholderPF?.length === 0) {
      openInfoDialog();
    } else {
      history.push('account-config');
    }
  }
  useEffect(() => {
    if (editMode === EDIT_STEPS.NO_EDIT) {
      setEditUuidsRealOwner({})
    }
  }, [editMode])

  async function onDeleteShareholderPF(uuid: string) {
    setUuidDelete(uuid);
    deleteDialogProps.openDialog();
  }

  async function deleteShareholder() {
    setLoading(true);
    await api.callService('delete-onboarding-form', {
      uuid: uuidDelete,
    });
    reloadForm();
    deleteDialogProps.onClose();
    setLoading(false);
  }

  async function onUpdateShareholderPF(uuid: string, percentage: string) {
    setUuidUpdate(uuid);
    setUpdatePercentage(parseInt(percentage));
    shareholderDrawerProps.openDrawer();
    close('');
  }

  function onDialogClose() {
    setUuidDelete(undefined);
    deleteDialogProps.onClose();
  }

  function onOpenSnackBar(eventName: string) {
    if (eventName === 'OnboardingFormUpdated') {
      show(SUCCESS_UPLOADING_INFO);
    }
    if (eventName === 'OnboardingFormCreated') {
      show(SUCCESS_SAVING_SHAREHOLDER);
    }
  }

  function editShareholder() {
    closeInfoDialogRealOwner();
    setEditMode(EDIT_STEPS.SELECT_CHECKBOX);
  }
  function addShareholder() {
    closeInfoDialogRealOwner();
    onAddShareholder()
  }
  function onSelectShareholderToEdit(uuid: string) {
    if(uuid === "") return
    setEditUuidsRealOwner((current: any) => {
      const tmp = { ...current };
      if (tmp[uuid]) {
        delete tmp[uuid]
      } else {
        tmp[uuid] = true
      }
      return tmp
    }
    );
  }
  const onCloseShareholderDrawer = () => {
    shareholderDrawerProps.onClose();
    setEditMode(EDIT_STEPS.NO_EDIT);
    onSelectShareholderToEdit(uuidUpdate || "")
  }


  if (loadingForm || loading)
    return (
      <OnBoardingLoader
        isLoading={loadingForm || loading || reloadingProfile}
      />
    );

  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        onConfirm={deleteShareholder}
        onClose={onDialogClose}
      />

      <InfoDialog
        open={infoDialogIsOpen}
        description={YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER_DESCRIPTION}
        title={YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER}
        onClose={closeInfoDialog}
      />
      <ConfirmationDialog
        open={infoDialogRealOwnerIsOpen}
        message={"Es esencial que cuentes con al menos un propietario real, el cual debe ser una persona física. Puedes modificar los accionistas actuales o agregar uno nuevo para que actúe como propietario real."}
        title={"Propietarios reales"}
        textCancel={allIsPM ? "Cancelar" : 'Editar accionistas'}
        textConfirm='Agregar nuevo'
        onConfirm={addShareholder}
        onClose={allIsPM ? closeInfoDialogRealOwner : editShareholder}
      />

      {moralShareholderDrawerProps.open && (
        <MoralShareholder
          open={moralShareholderDrawerProps.open}
          onClose={moralShareholderDrawerProps.onClose}
          uuidForm={uuidUpdate}
        />
      )}

      {shareholderDrawerProps.open && (
        <AddPhysicalShareholder
          open={shareholderDrawerProps.open}
          onClose={onCloseShareholderDrawer}
          uuidForm={uuidUpdate}
          openSnackbar={onOpenSnackBar}
          totalPercentageUsed={
            updatePercentage
              ? shareholderPercentageSum - updatePercentage
              : shareholderPercentageSum
          }
          isRealOwnerToComplete={editUuidsRealOwner[uuidUpdate || ""]}
        />
      )}

      <FlexGrid container direction='column' spacing={6}>
        <FlexGrid item container spacing={1} direction='column'>
          <FlexGrid item>
            <Typography variant='h2'>
              {editMode !== EDIT_STEPS.SELECT_CHECKBOX ? SHAREHOLDERS :
                "Editar accionistas"
              }</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>
              {editMode !== EDIT_STEPS.SELECT_CHECKBOX ? ADD_ALL_YOUR_SHAREHOLDERS_OF_YOUR_COMPANY :
                <><b>Elige a los accionistas a quienes deseas designar como propietarios reales.</b> Deberás completar información adicional sobre los seleccionados.</>}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid
          container
          item
          direction='column'
          style={{ marginBottom: '100px' }}
        >
          <FlexGrid item>
            <FlexGrid item container direction='column' spacing={2}>
              <FlexGrid item>
                {editMode !== EDIT_STEPS.SELECT_CHECKBOX ? <ActionCard
                  title={ADD_SHAREHOLDER}
                  onClick={onAddShareholder}
                  link
                  style={{ height: '80px' }}
                />
                  : <FlexGrid item style={{ background: theme.colors.manatee, padding: 20, borderRadius: 8 }} >
                    <Typography style={{ height: "auto" }} variant='paragraph' >
                      Declaro bajo protesta de decir verdad, que ninguna persona física, ya sea directa o indirectamente, posee el 25% o más de las acciones o del capital social de la empresa que represento. Por lo que los verdaderos propietarios son los administradores, quienes están a cargo de la gestión de la sociedad.
                    </Typography>
                  </FlexGrid>
                }
              </FlexGrid>
              {shareholderPF &&
                shareholderPF.map(({ name, uuid, percentage, status, type ,isRealOwner}, index) => (
                  <FlexGrid item fullWidth key={uuid}>
                    <FlexGrid container direction='row' fullWidth spacing={3} justify='center' alignItems='center' >
                      {editMode === EDIT_STEPS.SELECT_CHECKBOX && type === 'fisica' && <FlexGrid item justify='center' alignItems='center'>
                        <RadialButton
                          value={editUuidsRealOwner[uuid]}
                          onInput={(e) => onSelectShareholderToEdit(uuid)}
                        />
                      </FlexGrid>}
                      <FlexGrid item fullWidth>
                        <ActionCard
                          key={index}
                          title={name}
                          style={{ height: '80px' }}
                          status={status === 'pending' ? 'pending' : 'default'}
                          onDelete={() => onDeleteShareholderPF(uuid)}
                          onUpdate={() => onUpdateShareholderPF(uuid, percentage)}
                          description={`Accionista: ${percentage}% ${isRealOwner ? '(Propietario real)':""}`}
                          disabled={editMode === EDIT_STEPS.SELECT_CHECKBOX && type === 'moral'}
                          notAction={editMode === EDIT_STEPS.SELECT_CHECKBOX}
                          complementButton={
                            editMode === EDIT_STEPS.COMPLETE_FORM
                            && type === 'fisica'
                            && <Link underlined onClick={() => onUpdateShareholderPF(uuid, percentage)}>Completar formulario</Link>}
                        />
                      </FlexGrid>
                    </FlexGrid>
                  </FlexGrid>
                ))}
              {loadingForm &&
                shareholdersUuids.map(() => (
                  <Skeleton width='100%' height='80px' />
                ))}
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
      <BottomActions
        backButton
        continueButton
        onBack={onBack}
        onContinue={onContinue}
      />
    </>
  );
};
