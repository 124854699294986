import React, { useEffect, useMemo } from "react";
import { RoundedIcon, WizardStepperItem } from '@albo-ui/core';
import { useAuth } from "../../../../providers/auth-provider";
import { useApi } from "../../../../providers/api-provider";
import useLocalStorage from "../../../../hooks/use-local-storage";
import { useHistory } from "react-router";





export const IconGarantia = ({ color, size = "18" }: { color: string, size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6 0.199951C0.716 0.199951 0 0.915951 0 1.79995V12.9999C0 13.8839 0.716 14.5999 1.6 14.5999V4.99995C1.6 3.23515 3.0352 1.79995 4.8 1.79995H12.8C12.8 0.915951 12.084 0.199951 11.2 0.199951H1.6ZM4.8 3.39995C3.916 3.39995 3.2 4.11595 3.2 4.99995V16.1999C3.2 17.0839 3.916 17.7999 4.8 17.7999H14.4C15.284 17.7999 16 17.0839 16 16.1999V4.99995C16 4.11595 15.284 3.39995 14.4 3.39995H4.8ZM6.4 6.59995H12.8C13.2416 6.59995 13.6 6.95835 13.6 7.39995C13.6 7.84155 13.2416 8.19995 12.8 8.19995H6.4C5.9584 8.19995 5.6 7.84155 5.6 7.39995C5.6 6.95835 5.9584 6.59995 6.4 6.59995ZM6.4 9.79995H12.8C13.2416 9.79995 13.6 10.1583 13.6 10.5999C13.6 11.0415 13.2416 11.3999 12.8 11.3999H6.4C5.9584 11.3999 5.6 11.0415 5.6 10.5999C5.6 10.1583 5.9584 9.79995 6.4 9.79995ZM6.4 12.9999H12.8C13.2416 12.9999 13.6 13.3583 13.6 13.7999C13.6 14.2415 13.2416 14.5999 12.8 14.5999H6.4C5.9584 14.5999 5.6 14.2415 5.6 13.7999C5.6 13.3583 5.9584 12.9999 6.4 12.9999Z" fill={color} />
    </svg>
  )
}
export const IconCaratula = ({ color, size = "18" }: { color: string, size?: string }) => {
  return (<svg width={size} height={size} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 0C1.3455 0 0 1.3455 0 3V4.25C0 4.6645 0.336 5 0.75 5H2V17.75C2 18.9905 3.0095 20 4.25 20H15.75C16.9905 20 18 18.9905 18 17.75V3C18 1.3455 16.6545 0 15 0H3ZM3 1.5H12.4033C12.1468 1.9415 12 2.454 12 3V3.5H1.5V3C1.5 2.173 2.173 1.5 3 1.5ZM5.75 6H14.25C14.664 6 15 6.3355 15 6.75C15 7.1645 14.664 7.5 14.25 7.5H5.75C5.336 7.5 5 7.1645 5 6.75C5 6.3355 5.336 6 5.75 6ZM5.75 9H9.25C9.664 9 10 9.3355 10 9.75C10 10.1645 9.664 10.5 9.25 10.5H5.75C5.336 10.5 5 10.1645 5 9.75C5 9.3355 5.336 9 5.75 9ZM13.5 11C14.6135 11 15.5 11.8865 15.5 13C15.5 14.1135 14.6135 15 13.5 15C12.3865 15 11.5 14.1135 11.5 13C11.5 11.8865 12.3865 11 13.5 11ZM11.5 15.8652C12.0682 16.264 12.7575 16.5 13.5 16.5C14.2425 16.5 14.9318 16.264 15.5 15.8652V17.75C15.5 18.0175 15.358 18.2644 15.127 18.3984C15.0105 18.4659 14.88 18.5 14.75 18.5C14.6215 18.5 14.4929 18.4674 14.3779 18.4014L13.5 17.8994L12.6221 18.4014C12.3901 18.5344 12.1045 18.5329 11.873 18.3984C11.642 18.2644 11.5 18.0175 11.5 17.75V15.8652Z" fill={color} />
  </svg>
  )
}

export const IconAvisoPrivacidad = ({ color, size = "18" }: { color: string, size?: string }) => {
  return (<svg width={size} height={size} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 0C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H10C9.4 18.91 9 17.58 9 16H4V14H9V12H4V10H12V10.3691L14.6895 9.16992C15.1095 8.98992 15.57 8.95055 16 9.06055V6L10 0H2ZM9 1.5L14.5 7H9V1.5ZM15.5 11L11 13V16C11 19.915 14.22 21.743 15.5 22C16.78 21.743 20 19.915 20 16V13L15.5 11Z" fill={color} />
  </svg>
  )
}

const garantiaIcon = (
  <RoundedIcon
    icon={<IconGarantia color="#3E3E42" />}
    size='normal'
  />
);

const caratulaIcon = (
  <RoundedIcon
    icon={<IconCaratula color="#3E3E42" />}
    size='normal'
  />
);

const avisoPrivacidadIcon = (
  <RoundedIcon
    icon={<IconAvisoPrivacidad color="#3E3E42" />}
    size='normal'
  />
);
export interface ITemplate {
  version: string;
  type: TypeAggrement;
}
export type TypeAggrement = "contract" | "guarantee" | "notice-privacy"
export interface IAgreement {
  /**
   * Id de lead
   */
  leadUuid: string;
  /**
   * Marca de si esta firmado
   */
  isSigned: boolean;
  /**
   * Fecha de firma
   */
  signDate?: Date;
  completedDate?: Date;
  /**
   * Lugar de direccion
   */
  geoaddress?: string;
  /**
   * Informacion de template
   */
  template: ITemplate;
  /**
   * Involucrados
   */
  signers?: ISigners[];
  fileUrl?: string;
}

export interface ISigners {
  type: 'LEAD' | 'LEGAL_REPRESENTATIVE';
  referenceUuid: string;
  userUuid: string;
  isSigned: boolean;
  signDate?: Date;
  geoaddress?: string;
}
const defaultMetadata: IAgreement = { leadUuid: "", isSigned: false, template: { type: "contract", version: "1.0" }, signers: [] }
export const ICONS_AGREEMENTS = {
  contract: caratulaIcon,
  guarantee: garantiaIcon,
  "notice-privacy": avisoPrivacidadIcon

}

const defaultItems = [
  {
    icon: "contract",
    title: "Carátula, contrato y anexos",
    description: "Firma tu carátula, contrato y anexos",
    url: 'contract',
    status: "default",
    type: ["PM", "PFAE"],
    metadata: { ...defaultMetadata, template: { type: "contract", version: "1.0" } }
  },
  {
    icon: 'guarantee',
    title: "Garantía colateral",
    description: "Firma tu garantía colateral",
    url: 'guarantee',
    status: "default",
    type: ["PM", "PFAE"],
    metadata: { ...defaultMetadata, template: { type: "guarantee", version: "1.0" } }
  },
  {
    icon: 'notice-privacy',
    title: "Aviso de privacidad",
    description: "Firma tu aviso de privacidad",
    url: 'notice-privacy',
    status: "default",
    type: ["PFAE"],
    metadata: { ...defaultMetadata, template: { type: "notice-privacy", version: "1.0" } }
  },

]

export const useAgreementsToSign = () => {
  const [api] = useApi();
  const { user } = useAuth();
  const [items, setItems] = useLocalStorage("agreements-tosign", defaultItems);
  const history = useHistory();

  const stepsPFAE: WizardStepperItem[] = [
    {
      icon: <IconCaratula color="#fff" size="13" />,
      name: "Carátula, contrato y anexos",
      onClick: () => {
        history.push('contract');
      },
    },
    {
      icon: <IconGarantia color="#fff" size="13" />,
      name: "Garantía colateral",
      onClick: () => {
        history.push('guarantee');
      },
    },
    {
      icon: <IconAvisoPrivacidad color="#fff" size="13" />,
      name: "Aviso de privacidad",
      onClick: () => {
        history.push('notice-privacy');
      },
    }
  ];

  const stepsPM: WizardStepperItem[] = [
    {
      icon: <IconCaratula color="#fff" size="13" />,
      name: "Carátula, contrato y anexos",
      onClick: () => {
        history.push('contract');
      },
    },
    {
      icon: <IconGarantia color="#fff" size="13" />,
      name: "Garantía colateral",
      onClick: () => {
        history.push('guarantee');
      },
    }
  ];



  useEffect(() => {
    api.callQuery('get-agreements-by-user', {
      userUuid: user?.userUuid,
    }).then((response: any) => {
      const agreements = response.data.agreements;
      const newItems = defaultItems
      .filter(item=> agreements.some((agreement: any) => agreement?.template?.type === item.url))
      .map((item) => {
        const agreement = agreements.find((agreement: any) => agreement?.template?.type === item.url);
        if (agreement) {
          return {
            ...item,
            status: agreement?.signers?.some((signer: any) => signer?.userUuid === user?.userUuid && signer.isSigned) ? "done" : "default",
            metadata: agreement
          }
        }
        return item;
      })
      setItems(newItems);
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, user]);



  const allAgreementsIsSigned = useMemo(() => {
    return items.every((item) => item.status === "done");
  }, [items])

  const userItems = items.filter(item => item.type.includes(user?.leadType as string))

  return {
    items: userItems,
    steps: user?.leadType === "PFAE" ? stepsPFAE : stepsPM,
    allAgreementsIsSigned
  };
};