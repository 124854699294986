import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from '../../hooks/use-local-storage';
import { useApi } from '../api-provider';
import { useAuth } from '../auth-provider';

export type LocationProps = {
  latitude?: string;
  longitude?: string;
};

export type ContainerProps = {
  location: LocationProps;
};

export type GeoLocationServiceProps = {
  container: ContainerProps;
  ready: boolean
}
/**
 * Funcion para recuperar la posicion del cliente
 */
const GeoLocationService = () => {
  const [api] = useApi();
  const [moreInfo, setMoreInfo] = useLocalStorage<any | undefined>('moreInfo', undefined)
  const [container, setContainer] = useLocalStorage<ContainerProps | undefined>('container', undefined)
  const [count, setCount] = useState<number>(0);
  const [invoking, setInvoking] = useState<boolean>(false);
  const {isAuth} = useAuth()

  const getMoreDataGeolocalization = useCallback(location => {
    if (location && moreInfo === undefined && !invoking && count < 5 && isAuth) {
        const { latitude, longitude } = location;
        let countBefore = count;
        api.callService(
          'get-address-by-geolocation',
          {
            geolocation: {
              latitude: `${latitude}`,
              longitude: `${longitude}`
            }
          }
        ).then(response => {
          if (response.response === 'OK') {
            setMoreInfo(response.data?.address);
          } else {
            reinvokeApi(countBefore);
          }
        }).catch(e => {
          console.error('Error', e);
          reinvokeApi(countBefore);
        });

        if(countBefore === count) {
          setInvoking(true);
        }
    }
  }, [api, moreInfo, setMoreInfo, count, invoking, setInvoking,isAuth]);

  const reinvokeApi = (countBefore: number) => {
    countBefore++;
    setCount(countBefore);
    setInvoking(false);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {

        let locationCoords: LocationProps | undefined;

        if (container === undefined) {
          locationCoords = {
            latitude: `${position.coords.latitude}`,
            longitude: `${position.coords.longitude}`
          };

          const containerInfo: ContainerProps = {
            location: locationCoords
          };

          setContainer(containerInfo);
        }

        
      });
      
    } else {
      console.log('Geolocation is not supported for this Browser/OS.');
    }
  }, [container, getMoreDataGeolocalization, setContainer]);

  const containerLocationCoords = container?.location

  useEffect(() => {
    if (containerLocationCoords && moreInfo === undefined){
      getMoreDataGeolocalization(containerLocationCoords);
    }
  }, [containerLocationCoords, getMoreDataGeolocalization, moreInfo]);

  return { container, getMoreDataGeolocalization }
};

export default GeoLocationService;