import {
  Button,
  FlexGrid,
  Form,
  FormItem,
  FormItemProps,
  Link,
  Typography,
  useDialog,
  useForm,
  useThemeProvider,
} from '@albo-ui/core';
import { useHistory } from 'react-router';
import { useCatalog } from '../../hooks/use-catalog';
import { SignUpParams, useSignUp } from '../../providers/sign-up-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { FormPhoneInput } from '../form-phone-input';
import { OnBoardingLoader } from '../on-boarding-loader';
import { UserAlreadyExistDialog } from '../user-already-exist-dialog';
import { useEffect, useState } from 'react';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import useLocalStorage from '../../hooks/use-local-storage';
import { adviceOfPrivacyUrl } from '../../const';

export type SignUpFormData = {
  confirmPassword?: string;
  typeDesc?: string;
} & SignUpParams;

export const SignUpForm = ({obOwner}: {obOwner: string}) => {
  const formProps = useForm<
    | SignUpFormData
    | {
        confirmPassword: string;
        password: string;
        type: string;
        typeDesc: string;
        general: {
          phone: {
            phoneCode: string;
            phoneCodeDesc: string;
            phoneCountry: string;
          };
        };
        onboardingVersion: string;
        owner: {
          hash: string;
        }
      }
  >({
    password: '',
    confirmPassword: '',
    type: '',
    typeDesc: '',
    general: {
      phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
    },
    onboardingVersion: 'QUICK_WIN',
    owner:{
      hash: obOwner,
    },
  });
  const { theme } = useThemeProvider();
  const { signUp, loading, error } = useSignUp();
  const history = useHistory();
  const validators = useValidators();
  const userExistDialogProps = useDialog();
  const defaultValidations = useDefaultValidations();
  const [numberOfEmployees] = useCatalog('NumberOfEmployees');
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [tempData, setTempData] = useLocalStorage<any>('tempData', undefined);

  const EyeSlashIcon = <FiEyeOff size='20px' />;
  const EyeIcon = <FiEye size='20px' />;

  const {
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    PERSON_TYPE_PLACEHOLDER,
    CREATE_PASSWORD,
    CONFIRM_YOUR_PASSWORD,
    PASSWORD_HELP_TITLE,
    AT_LEAST_EIGHT_CHARACTERS,
    NO_MORE_THAN_TWO_SECUENCIAL_CHARACTERS,
    NO_MORE_THAN_TWO_EQUAL_CHARACTERS,
    DO_NOT_INCLUDE_WORD_ALBO_OR_YOUR_BUSINESS_NAME,
    SIGN_UP_FORM_FORM_TITLE,
    SIGN_UP_FORM_FORM_TEXT,
    CONTINUE,
    MORAL_PERSON,
    PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY,
    REQUIRED_FIELD,
    SELECT_OPTION,
    PASSWORD_DOES_NOT_MATCH,
    ADVICE_OF_PRIVACY,
    ON_CLICK_CONTINUE_YOU_ACCEPT_THE,
    CORPORATE_EMAIL,
    CORPORATE_EMAIL_HELP,
    CORPORATE_EMAIL_HELP_TITLE,
    CONTACT_PHONE,
    SIZE_OF_YOUR_TEAM,
    DISCLAIMER
  } = useTranslator();

  const selectItems = [
    {
      name: PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY,
      value: 'PFAE',
    },
    {
      name: MORAL_PERSON,
      value: 'PM',
    },
  ];

  const numberOfEmployeesSelect = numberOfEmployees
    .map((number: any) => ({
      value: number.key,
      name: number.value,
    }))
    .sort((a: any, b: any) => a.value - b.value);

  const PasswordHelp = (
    <FlexGrid container fullWidth spacing={1} direction='column'>
      <UserAlreadyExistDialog
        open={userExistDialogProps.open}
        onClose={onComeBackToHomeSiteClick}
        onConfirm={goToLogin}
      />
      <FlexGrid item>
        <Typography variant='paragraph' color='white'>
          - {AT_LEAST_EIGHT_CHARACTERS}
        </Typography>
      </FlexGrid>
      <FlexGrid item>
        <Typography variant='paragraph' color='white'>
          - {NO_MORE_THAN_TWO_SECUENCIAL_CHARACTERS}
        </Typography>
      </FlexGrid>
      <FlexGrid item>
        <Typography variant='paragraph' color='white'>
          - {NO_MORE_THAN_TWO_EQUAL_CHARACTERS}
        </Typography>
      </FlexGrid>
      <FlexGrid item>
        <Typography variant='paragraph' color='white'>
          - {DO_NOT_INCLUDE_WORD_ALBO_OR_YOUR_BUSINESS_NAME}
        </Typography>
      </FlexGrid>
    </FlexGrid>
  );

  const errorMessages = {
    input: REQUIRED_FIELD,
    select: SELECT_OPTION,
  };

  function onPasswordIconClick() {
    setHidePassword(!hidePassword);
  }

  function onConfirmPasswordIconClick() {
    setHideConfirmPassword(!hideConfirmPassword);
  }

  const validatePasswords = (value: string) => {
    const passwordsDoNotMatch =
      formProps.data.password !== formProps.data.confirmPassword &&
      formProps.data.password.length !== 0 &&
      formProps.data.confirmPassword?.length !== 0;

    return passwordsDoNotMatch ? PASSWORD_DOES_NOT_MATCH : undefined;
  };

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: CORPORATE_EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      inputType: 'email',
      help: {
        title: CORPORATE_EMAIL_HELP_TITLE,
        description: CORPORATE_EMAIL_HELP,
      },
      ...defaultValidations.emailValidations(),
    },
    {
      name: 'general.phone',
      type: 'phone',
      placeholder: CONTACT_PHONE,
      required: true,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: SIZE_OF_YOUR_TEAM,
      name: 'employeesQuantity',
      type: 'select',
      items: numberOfEmployeesSelect,
      required: true,
      errorMessage: errorMessages.select,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: PERSON_TYPE_PLACEHOLDER,
      name: 'type',
      type: 'select',
      items: selectItems,
      required: true,
      errorMessage: errorMessages.select,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: CREATE_PASSWORD,
      name: 'password',
      type: 'input',
      inputType: hidePassword ? 'password' : 'default',
      pattern: 'password',
      required: true,
      onClickIcon: onPasswordIconClick,
      icon: hidePassword ? EyeSlashIcon : EyeIcon,
      validators: validators.getPasswordValidators(),
      help: {
        title: PASSWORD_HELP_TITLE,
        description: PasswordHelp,
      },
    },
    {
      placeholder: CONFIRM_YOUR_PASSWORD,
      name: 'confirmPassword',
      required: true,
      type: 'input',
      pattern: 'password',
      inputType: hideConfirmPassword ? 'password' : 'default',
      icon: hideConfirmPassword ? EyeSlashIcon : EyeIcon,
      onClickIcon: onConfirmPasswordIconClick,
      validators: [validators.isEmpty, validatePasswords],
    },
  ];

  const onClickButton = async () => {
    if (formProps.hasErrors) {
      formProps.displayErrors();
    } else {
      delete formProps.data.confirmPassword;
      delete formProps.data.typeDesc;

      await signUp(formProps.data as any)
        .then(() => {
          if (!error) history.push('confirm-email');
        })
        .catch(() => {
          history.push('/sign-up');
        });
      const tempData = { ...formProps.data, password: '' };
      setTempData(tempData);
    }
  };

  function onComeBackToHomeSiteClick() {}

  function goToLogin() {
    history.push('login');
  }

  const setFormData = formProps.setData;

  useEffect(() => {
    if (tempData) {
      setFormData(tempData);
      setTempData(undefined);
    }
  }, [tempData, setFormData, setTempData]);

  if (loading) return <OnBoardingLoader isLoading={loading} />;
  return (
    <FlexGrid
      item
      container
      fullHeight
      fullWidth
      direction='column'
      alignItems='center'
      spacing={6}
    >
      <FlexGrid
        item
        container
        direction='column'
        spacing={1}
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='h2'>{SIGN_UP_FORM_FORM_TITLE}</Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='paragraph'>{SIGN_UP_FORM_FORM_TEXT}</Typography>
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item container fullWidth direction='column' spacing={2}>
        <FlexGrid item>
          <Typography variant='small' color='neutral'>
            <span style={{ color: theme.colors.required }}>*</span>{' '}
            {REQUIRED_FIELD}
          </Typography>
        </FlexGrid>
        <Form {...formProps} onSubmit={onClickButton}>
          <FlexGrid item container wrap fullWidth spacing={4}>
            {inputs.map(
              ({
                placeholder,
                name,
                type,
                items,
                inputType,
                help,
                required,
                error,
                validators,
                errorMessage,
                maxLength,
                icon,
                pattern,
                onClickIcon,
              }) =>
                type !== 'phone' ? (
                  <FlexGrid
                    item
                    style={{
                      width: name === 'type' ? '100%' : 'calc(50% - 16px)',
                    }}
                  >
                    <FormItem
                      placeholder={placeholder}
                      name={name}
                      type={type}
                      items={items}
                      inputType={inputType}
                      help={help}
                      required={required}
                      error={error}
                      errorMessage={errorMessage}
                      validators={validators}
                      maxLength={maxLength}
                      icon={icon}
                      pattern={pattern}
                      onClickIcon={onClickIcon}
                    />
                  </FlexGrid>
                ) : (
                  <FlexGrid
                    item
                    style={{
                      width: 'calc(50% - 16px)',
                    }}
                  >
                    <FormPhoneInput
                      baseName={name}
                      required={required}
                      validators={validators}
                      placeholder={placeholder}
                    />
                  </FlexGrid>
                )
            )}
          </FlexGrid>
        </Form>
      </FlexGrid>
      <FlexGrid item fullWidth container>
        <FlexGrid item container fullWidth direction='column' spacing={2}>
          <FlexGrid item fullWidth>
            <Button onClick={onClickButton} size='fullWidth'>
              {CONTINUE}
            </Button>
          </FlexGrid>
          {formProps.data.type === 'PFAE' && (
            <FlexGrid item fullWidth style={{ textAlign: 'center' }}>
              <Typography variant='small' color='charcoal'>
                {ON_CLICK_CONTINUE_YOU_ACCEPT_THE}{' '}
                <Link href={adviceOfPrivacyUrl} underlined>
                  {ADVICE_OF_PRIVACY}
                </Link>
              </Typography>
            </FlexGrid>
          )}
          <FlexGrid item style={{ marginTop: theme.spacesPx[7] }}>
            <Typography variant='small' color='charcoal'>
              {DISCLAIMER}
            </Typography>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
