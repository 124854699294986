import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Home } from './home';
import { SignContract } from './sign-contract';
import { SignGuarantee } from './sign-guarantee';
import { SignNotice } from './sign-notice';




export const DocumentSigningRouter = () => {
    const { url } = useRouteMatch();


    return <Switch>
        <Route path={`${url}/sign/contract`} component={SignContract} />
        <Route path={`${url}/sign/guarantee`} component={SignGuarantee} />
        <Route path={`${url}/sign/notice-privacy`} component={SignNotice} />
        <Route path={`${url}/sign`} component={Home} />
        <Redirect to={`${url}/sign`} />
    </Switch>

};
