
import React from "react";
import { Button, DefaultComponentProps, Dialog, DialogActions, DialogContent, DialogTitle, FlexGrid, Typography } from '@albo-ui/core';

export type ConfirmDialogDialogProps = {
    onConfirm: () => void;
    onClose: () => void;
    open: boolean;
    title: string;
    message: string;
    style?: "warning" | "error" | "success" | "info";
    textConfirm?: string;
    textCancel?: string;
} & DefaultComponentProps;

export const ConfirmationDialog = ({ onConfirm, onClose, open, title, message
    , textConfirm = "Aceptar"
    , textCancel = "Cancelar"
    , style = "info"
}: ConfirmDialogDialogProps) => {
    return (
        <Dialog onClose={onClose} open={open} style={{ width: '600px', maxWidth: "600px" }}>
            <DialogTitle>
                <Typography variant="smallTitle">{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <FlexGrid
                    // container
                    fullHeight
                    fullWidth
                    direction="column"
                    justify='space-between'
                    alignItems='flex-start'
                    spacing={2}
                >
                    <Typography variant="paragraph">{message}</Typography>
                </FlexGrid>
            </DialogContent>
            <DialogActions>
                <FlexGrid
                    // container
                    fullHeight
                    fullWidth
                    justify='space-between'
                    alignItems='flex-end'
                    style={{ marginTop: '20px', display: "flex" }}
                >
                    <FlexGrid item justify="center" container alignItems="center">
                        <Button onClick={onClose} buttonType='secondary' size='normal'>
                            {textCancel}
                        </Button>
                    </FlexGrid>
                    <FlexGrid item justify="center" container alignItems="center">
                        <Button onClick={onConfirm} size='normal'
                            style={{
                                width: "fit-content",
                                minWidth: "205px",
                                padding: "0px 10px"
                            }}
                        >
                            {textConfirm}
                        </Button>
                    </FlexGrid>
                </FlexGrid>
            </DialogActions>
        </Dialog>
    )
}