import {
  CheckBox,
  FlexGrid,
  Form,
  FormItemProps,
  Typography,
  useCheckBox,
  useThemeProvider,
} from '@albo-ui/core';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useAddressInputs } from '../../common';
import { mainCountry } from '../../const';
import { useCatalog } from '../../hooks/use-catalog';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { usePfaeStatus } from '../../hooks/use-pfae-status';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { FormItems } from '../form-items';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';


export const CompanyInfoAndAccountForm = () => {
  const formProps = useOnboardingForm({
    defaultParams: {
      cifAddress: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
      notificationAddress: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
    },
    onboardingFormType: 'QUICK_WIN_COMPANY_INFO_ACCOUNT',
    nextSectionUrl: 'beneficiaries',
    singleton: true,
  });
  const { theme } = useThemeProvider();
  const history = useHistory();
  const [activities] = useCatalog('PFAEActivities');
  const validators = useValidators();
  const defaultValidations = useDefaultValidations();
  const pfaeStatus = usePfaeStatus();
  const companyInfoAccountStatus =
    pfaeStatus.companyInfoAccountStatus !== 'done';
  const homeCBS = useOnHomeCBS();
  const checkBoxProps = useCheckBox();
  const checkBoxValue = checkBoxProps.value;
  const hasCheckedAddress = useRef<boolean>(false);
  const toggleCheckBox = checkBoxProps.onInput;
  const defaultValidators = useDefaultValidations();
  const address = JSON.stringify(formProps.form.data.cifAddress);
  const physicalAddress = JSON.stringify(
    formProps.form.data.notificationAddress
  );
  const addressesAreTheSame = address === physicalAddress;
  homeCBS.setShowDialog(companyInfoAccountStatus);

  const activitiesSelect = activities.map((acitivity: any) => ({
    value: acitivity.key,
    name: acitivity.value,
  }));

  const {
    HELP_TITLE,
    COMPANY_INFO_ACCOUNT_FORM_TEXT,
    COMPANY_INFO_ACCOUNT,
    REQUIRED_FIELD,
    RFC_WITH_HOMOCLAVE,
    THIRTEEN_CHARACTER_ALPHANUMERIC_KEY,
    RFC_CEDULE,
    COMERCIAL_NAME,
    COMERCIAL_NAME_HELP_DESCRIPTION,
    MAIN_BUSINESS_ACTIVITY,
    MAIN_BUSINESS_ACTIVITY_HELP_DESCRIPTION,
    ADDRESS_VOUCHER,
    ADDRESS_VOUCHER_HELP_DESCRIPTION,
    SOURCE_ACCOUNT_RESOURCES,
    EXPECTED_RESOURCES_USAGE,
    EXPECTED_RESOURCES_USAGE_HELP_DESCRIPTION,
    NO_MORE_THAN_THREE_MONTHS,
    PHYSICAL_ADDRESS,
    IS_THE_SAME_AS_CIF_ADDRESS,
    ADDRESS_REGISTERED_CIF,
    UPDATE,
    CONTINUE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  const { formFields, findFields } = useFormsFields();

    useEffect(() => {
      findFields('QUICK_WIN_COMPANY_INFO_ACCOUNT');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  const businessDataInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_INFO_ACCOUNT', [
    {
      placeholder: COMERCIAL_NAME,
      name: 'comercialName',
      type: 'input',
      required: true,
      ...defaultValidations.comercialNameValidations(),
      help: {
        title: HELP_TITLE,
        description: COMERCIAL_NAME_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: SOURCE_ACCOUNT_RESOURCES,
      name: 'sourceOfAccountResources',
      type: 'input',
      required: true,
      ...defaultValidators.sourceOfAccountResourcesValidations(),
    },
    {
      placeholder: EXPECTED_RESOURCES_USAGE,
      name: 'resourcesDestination',
      required: true,
      type: 'input',
      ...defaultValidations.resourcesDestinationValidations(),
      help: {
        description: EXPECTED_RESOURCES_USAGE_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: `${ADDRESS_VOUCHER} ${NO_MORE_THAN_THREE_MONTHS}`,
      name: 'cifProofOfAddressFile',
      type: 'fileInput',
      validators: [validators.isEmpty],
      required: true,
      help: {
        title: HELP_TITLE,
        description: ADDRESS_VOUCHER_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: `${RFC_CEDULE} ${NO_MORE_THAN_THREE_MONTHS}`,
      name: 'taxidFile',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: ADDRESS_VOUCHER_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: MAIN_BUSINESS_ACTIVITY,
      name: 'economicActivity',
      type: 'select',
      items: activitiesSelect,
      required: true,
      validators: [validators.selectIsEmpty],
      help: {
        title: HELP_TITLE,
        description: MAIN_BUSINESS_ACTIVITY_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'taxid',
      type: 'input',
      required: true,
      toUpperCase: true,
      ...defaultValidations.rfcWithHomoclaveValidations(),
      help: {
        title: HELP_TITLE,
        description: THIRTEEN_CHARACTER_ALPHANUMERIC_KEY,
      },
    },
  ]);

  const cifAddress: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_INFO_ACCOUNT', useAddressInputs({
    propName: 'cifAddress',
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.cifAddress.zip,
    formData: formProps.form.data,
  }));

  const notificationAddress: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_INFO_ACCOUNT', useAddressInputs({
    disabled: checkBoxValue,
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.notificationAddress.zip,
    propName: 'notificationAddress',
    formData: formProps.form.data,
  }));

  function onBack() {
    history.push('personal-info');
  }

  const setFormData = formProps.form.setData;

  useEffect(() => {
    if (checkBoxValue) {
      setFormData((currentData: any) => {
        return {
          ...currentData,
          notificationAddress: {
            ...currentData.cifAddress,
          },
        };
      });
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        notificationAddress: {
          country: mainCountry,
          countryDesc: 'Mexico',
        },
      }));
    }
  }, [checkBoxValue, setFormData]);

  useEffect(() => {
    if (
      addressesAreTheSame &&
      !hasCheckedAddress.current &&
      address !== `{"country":"${mainCountry}","countryDesc":"Mexico"}`
    ) {
      toggleCheckBox();
      hasCheckedAddress.current = true;
    } else if (
      address !== `{"country":"${mainCountry}","countryDesc":"Mexico"}`
    ) {
      hasCheckedAddress.current = true;
    }
  }, [addressesAreTheSame, toggleCheckBox, address]);

  if (formProps.saving || formProps.movingForward || formProps.loadingForm)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : formProps.moveForward}>
      <DialogConfirm />
      <FlexGrid container fullHeight fullWidth direction='column' spacing={3}>
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{COMPANY_INFO_ACCOUNT}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>
              {COMPANY_INFO_ACCOUNT_FORM_TEXT}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='small' color='neutral' bold>
            <span style={{ color: theme.colors.required }}>*</span>{' '}
            {REQUIRED_FIELD}
          </Typography>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={businessDataInputs} />
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='smallTitle'>{ADDRESS_REGISTERED_CIF}</Typography>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={cifAddress} />
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='smallTitle'>{PHYSICAL_ADDRESS}</Typography>
        </FlexGrid>
        <FlexGrid item>
          <CheckBox
            onInput={checkBoxProps.onInput}
            value={checkBoxValue}
            text={IS_THE_SAME_AS_CIF_ADDRESS}
          />
        </FlexGrid>
        <FlexGrid item container fullWidth style={{ marginBottom: '100px' }}>
          <FormItems inputs={notificationAddress} />
        </FlexGrid>
      </FlexGrid>

      

      <BottomActions
        backButton
        saveProgressButton={companyInfoAccountStatus && !isOpsAnalyst}
        saveButtonText={SAVE_PROGRESS}
        continueButton
        continueButtonText={isOpsAnalyst ? UPDATE : CONTINUE}
        onBack={onBack}
        onSaveProgress={formProps.saveForm}
        onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
      />
    </Form>
  );
};
