import { Button, FlexGrid, Image, Typography } from '@albo-ui/core';
import { useTranslator } from '../../../providers/translator-provider';
import contract from '../../../images/File.svg';
import { useHistory } from 'react-router';
import { useService } from '../../../hooks/use-service';
import { useAuth } from '../../../providers/auth-provider';
import { OnBoardingLoader } from '../../on-boarding-loader';

export type DocumentStatus =
  | 'documents-required'
  | 'pending'
  | 'revision'
  | 'request-extrainfo'
  | 'pending-user-approval'
  | 'abandoned';

export type InteractionStatus =
  | "pending-user-approval"
  | "approved-by-user";

export type DocumentsProps = {
  interactionStatus?: InteractionStatus;
  status: DocumentStatus;
};

export const Documents = ({ status, interactionStatus }: DocumentsProps) => {
  const history = useHistory();
  const {
    THANKS_WE_ARE_REVISING_YOUR_DOCUMENTS,
    DOCUMENTS_REQUIRED_DESCRIPTION,
    EXTRA_INFO_REQUIRED_DESCRIPTION,
    WE_NEED_ADITIONAL_INFO,
    UPLOAD_INFO,
    DOCUMENTS_PENDING,
    PENDING_INFO,
    SHOW_DOCS,
    STATUS,
    REVISION_PENDING_TITLE,
    REVISION_PENDING_FIRST_DESCRIPTION,
    REVISION_PENDING_SECOND_DESCRIPTION,
    REVIEW_MY_INFO,
    REVISION_PENDING
  } = useTranslator();

  const { user, reloadProfile, reloadingProfile } = useAuth();

  const [, , startAdditionalInfoLoading, startAdditionalInfo] = useService(
    {
      name: 'start-additional-info',
      params: {
        leadUuid: user?.leadUuid,
      },
    },
    []
  );

  const statusIndex: DocumentStatus = interactionStatus === 'pending-user-approval'
    ? 'pending-user-approval'
    : status;

  const statusTitle = {
    'documents-required': WE_NEED_ADITIONAL_INFO,
    revision: THANKS_WE_ARE_REVISING_YOUR_DOCUMENTS,
    'pending-user-approval': REVISION_PENDING_TITLE,
    'request-extrainfo': WE_NEED_ADITIONAL_INFO,
    pending: THANKS_WE_ARE_REVISING_YOUR_DOCUMENTS,
    abandoned: THANKS_WE_ARE_REVISING_YOUR_DOCUMENTS,
  };

  const description = {
    'documents-required': [DOCUMENTS_REQUIRED_DESCRIPTION],
    revision: [EXTRA_INFO_REQUIRED_DESCRIPTION],
    'pending-user-approval': [
      REVISION_PENDING_FIRST_DESCRIPTION,
      REVISION_PENDING_SECOND_DESCRIPTION,
    ],
    'request-extrainfo': [WE_NEED_ADITIONAL_INFO],
    pending: [EXTRA_INFO_REQUIRED_DESCRIPTION],
    abandoned: [EXTRA_INFO_REQUIRED_DESCRIPTION],
  };

  const statusDescription = {
    revision: DOCUMENTS_PENDING,
    'pending-user-approval': REVISION_PENDING,
    'abandoned': REVISION_PENDING,
    'request-extrainfo': PENDING_INFO,
  };

  const onClickShowDocumentsButton = async () => {
    try {
      await startAdditionalInfo();
      reloadProfile();
    } catch (error) { }
  };

  const onClickUploadButton = () => {
    history.push('upload-documents');
  };

  const onClickReviewMyInfoButton = () => {
    history.push('menu');
  };

  if (startAdditionalInfoLoading || reloadingProfile)
    return (
      <OnBoardingLoader
        isLoading={reloadingProfile || startAdditionalInfoLoading}
      />
    );

  return (
    <FlexGrid
      container
      direction='column'
      spacing={5}
      alignItems='center'
      style={{ maxWidth: '530px' }}
    >
      <FlexGrid item>
        <Image src={contract} size='123px' />
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='smallTitle'>{statusTitle[statusIndex]}</Typography>
        </FlexGrid>

        {description[statusIndex].map((text) => (
          <FlexGrid item>
            <Typography variant='paragraph'>{text}</Typography>
          </FlexGrid>
        ))}
      </FlexGrid>

      {(status === 'revision' ||
        status === 'request-extrainfo' || status === "abandoned") && (
          <FlexGrid
            item
            container
            spacing={1}
            direction='column'
            alignItems='center'
            style={{ textAlign: 'center' }}
          >
            <FlexGrid item>
              <Typography variant='paragraph'>{STATUS}</Typography>
            </FlexGrid>
            <FlexGrid item>
              <Typography
                variant='smallTitle'
                color={
                  status === 'request-extrainfo'
                    ? 'sunset'
                    : 'paragraph'
                }
              >
                {statusDescription[status]}
              </Typography>
            </FlexGrid>
          </FlexGrid>
        )}

      {status === 'request-extrainfo' && (
        <FlexGrid item>
          <Button onClick={onClickShowDocumentsButton} size='large'>
            {SHOW_DOCS}
          </Button>
        </FlexGrid>
      )}
      {status === 'documents-required' && (
        <FlexGrid item>
          <Button onClick={onClickUploadButton} size='large'>
            {UPLOAD_INFO}
          </Button>
        </FlexGrid>
      )}
      {interactionStatus === 'pending-user-approval' && (
        <FlexGrid item>
          <Button onClick={onClickReviewMyInfoButton} size='large'>
            {REVIEW_MY_INFO}
          </Button>
        </FlexGrid>
      )}
    </FlexGrid>)
};
