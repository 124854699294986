import {
  FaBriefcase,
  FaRegBuilding,
  FaRegCreditCard,
  FaLock,
  FaFolderOpen,
  FaEnvelope,
  FaUserTie,
  FaUserFriends,
} from 'react-icons/fa';
import { useThemeProvider } from '@albo-ui/core';

import { useTranslator } from '../../providers/translator-provider';
import { ReactNode } from 'react';

export type TopCard = { icon: ReactNode; text: string }[];
export type RightSideList = {
  icon: ReactNode;
  title: string;
  list: string[];
};

export const GetMainItems = (): {
  topCards: TopCard;
  bottomItems: {
    icon: ReactNode;
    text: string;
    color: keyof typeof theme.colors;
    width: string;
  }[];
} => {
  const { theme } = useThemeProvider();
  const {
    ON_BOARDING_INTRO_PM_TOP_FIRST_ITEM_TEXT,
    ON_BOARDING_INTRO_PM_TOP_SECOND_ITEM_TEXT,
    ON_BOARDING_INTRO_PM_TOP_THIRD_ITEM_TEXT,
    ON_BOARDING_INTRO_BOTTOM_FIRST_ITEM_TEXT,
    ON_BOARDING_INTRO_BOTTOM_SECOND_ITEM_TEXT,
    ON_BOARDING_INTRO_BOTTOM_THIRD_ITEM_TEXT,
  } = useTranslator();

  const briefCase = <FaBriefcase size='40px' color={theme.colors.indigo} />;
  const folderInfo = <FaFolderOpen size='40px' color={theme.colors.indigo} />;
  const creditCard = (
    <FaRegCreditCard size='40px' color={theme.colors.indigo} />
  );
  const lock = <FaLock size='13px' color={theme.colors.white} />;
  const folder = <FaFolderOpen size='13px' color={theme.colors.white} />;
  const email = <FaEnvelope size='13px' color={theme.colors.white} />;

  return {
    topCards: [
      {
        icon: folderInfo,
        text: ON_BOARDING_INTRO_PM_TOP_SECOND_ITEM_TEXT,
      },      
      {
        icon: briefCase,
        text: ON_BOARDING_INTRO_PM_TOP_FIRST_ITEM_TEXT,
      },
      {
        icon: creditCard,
        text: ON_BOARDING_INTRO_PM_TOP_THIRD_ITEM_TEXT,
      },
    ],
    bottomItems: [
      {
        icon: lock,
        text: ON_BOARDING_INTRO_BOTTOM_FIRST_ITEM_TEXT,
        color: 'vivere',
        width: '211px',
      },
      {
        icon: folder,
        text: ON_BOARDING_INTRO_BOTTOM_SECOND_ITEM_TEXT,
        color: 'bolt',
        width: '269px',
      },
      {
        icon: email,
        text: ON_BOARDING_INTRO_BOTTOM_THIRD_ITEM_TEXT,
        color: 'primary',
        width: '191px',
      },
    ],
  };
};

export const GetRightSideLists = (): { lists: RightSideList[] } => {
  const { theme } = useThemeProvider();
  const building = <FaRegBuilding size='20px' color={theme.colors.white} />;
  const user = <FaUserTie size='20px' color={theme.colors.white} />;
  const userFriends = <FaUserFriends size='20px' color={theme.colors.white} />;

  const {
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_FIRST_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_SECOND_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_THIRD_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_SUBTITLE,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_LIST_FIRST_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_LIST_SECOND_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_SUBTITLE,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_FIRST_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_FOURTH_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_SECOND_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_THIRD_ITEM,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_SUBTITLE,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_FOUR_ITEM
  } = useTranslator();

  return {
    lists: [
      {
        icon: building,
        title: ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_SUBTITLE,
        list: [
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_FIRST_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_SECOND_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_THIRD_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_FOUR_ITEM
        ],
      },
      {
        icon: user,
        title: ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_SUBTITLE,
        list: [
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_LIST_FIRST_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_LIST_SECOND_ITEM,
        ],
      },
      {
        icon: userFriends,
        title: ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_SUBTITLE,
        list: [
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_FIRST_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_SECOND_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_THIRD_ITEM,
          ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_FOURTH_ITEM,
        ],
      },
    ],
  };
};
