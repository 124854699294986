
import { FaLock } from 'react-icons/fa';

import { ReactNode } from 'react';
import { Card, CheckBox, ContractVisualizer, Description, FlexGrid, Typography, useThemeProvider } from '@albo-ui/core';
import { SignContainer } from '@albo-ui/core/src/components/sign-contract/styled';
import { SignCard } from '../../../components-quick-win/sign-card';
import { useIsMobile } from '../../../hooks/use-is-mobile';


export type SignStatusItem = {
    name: string;
    statusText: string | ReactNode;
    status: 'done' | 'pending';
};

export type SignButton = {
    text: string;
    description: string;
};

export type SignContractProps = {
    name?: string;
    contractType?: string;
    signerType?: string;
    srcDoc: string;
    checkBoxText?: string;
    signCardDescription?: string;
    signButton: SignButton;
    value?: boolean;
    isSigned?: boolean; 
    dateSigned?: string;
    onInput?: (event: boolean) => void;
    onSignButtonClick?: () => void;
};

export const ContractDocument = ({
    name,
    signerType,
    srcDoc,
    checkBoxText,
    signButton,
    onInput,
    value,
    signCardDescription,
    isSigned,
    dateSigned,
    onSignButtonClick,
}: SignContractProps) => {
    const { theme } = useThemeProvider();
    const isMobile = useIsMobile();
    return (
        <FlexGrid container fullHeight fullWidth
            direction={isMobile ? "column" : "row"}
            alignItems={"flex-start"}

        >



            <FlexGrid
                item
                container
                direction='column'
                style={{ width: isMobile ? '100%' : "65%", paddingTop: theme.spacesPx[4] }}
                fullHeight
            >
                <FlexGrid item fullHeight fullWidth>
                    <ContractVisualizer fullWidth fullHeight src={srcDoc} 
                    style={{ height: isMobile ? '50vh' : "100%" }}
                    />
                </FlexGrid>
                <FlexGrid item >
                 {!isSigned &&   <Card fullWidth style={{
                        height:isMobile?"200px":"103px",
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                }}>
                        <CheckBox
                            value={value !== undefined ? value : true}
                            onInput={onInput ? onInput : () => { }}
                            style={{ backgroundColor: 'white' ,}}
                            text={checkBoxText}
                        />
                    </Card>}
                </FlexGrid>
            </FlexGrid>

            <FlexGrid
                item
                style={{
                    width: isMobile?"100%":'35%',
                }}
                fullHeight
                padding={isMobile ? 4 : 0}
            >
                <SignContainer
                    style={{
                        padding: `${theme.spacesPx[5]} ${theme.spacesPx[4]}`,
                    }}
                >
                    <FlexGrid
                        container
                        fullWidth
                        fullHeight
                        direction='column'
                        spacing={3}
                    >


                        <FlexGrid
                            item
                            container
                            direction='column'
                            spacing={2}
                            fullHeight
                            alignItems='center'
                            justify='center'
                        >
                            <FlexGrid item fullWidth>
                                <SignCard
                                    fullWidth
                                    height='160px'
                                    name={name ? name : ''}
                                    type={signerType}
                                    buttonText={signButton?.text}
                                    disabled={!value}
                                    onClick={onSignButtonClick}
                                    isSigned={isSigned}
                                    signDate={dateSigned}
                                />
                            </FlexGrid>

                            <FlexGrid item>
                                <Description
                                    icon={
                                        <FaLock
                                            color={theme.colors.lightNeutral}
                                            width='11px'
                                            height='13px'
                                        />
                                    }
                                >
                                    <Typography
                                        variant='small'
                                        style={{ marginLeft: '-12px' }}
                                    >
                                        {signCardDescription}
                                    </Typography>
                                </Description>
                            </FlexGrid>

                        </FlexGrid>

                    </FlexGrid>
                </SignContainer>
            </FlexGrid>
        </FlexGrid>
    );
};
