import { Layout } from './layout';
import { DocumentSigningRouter } from './router';

export const DocumentSigning = () => {
  return (
    <Layout>
      <DocumentSigningRouter />
    </Layout>
  );
};
