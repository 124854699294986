import { BreadcrumItem } from "@albo-ui/core";
import { Sign } from "../sign"
import { useAuth } from "../../../providers/auth-provider";
import { useAgreementsToSign } from "../home/hooks/use-agreements-to-sign";

export const SignNotice = () => {
    const { user } = useAuth();
    const { items } = useAgreementsToSign();
    const agreement = items.find(item => item.url === 'notice-privacy')

    const breadCrumItems: BreadcrumItem[] = [
        {
            name: "Inicio",
            nameKey: 'home',
            to: '/sign',
        },
        {
            name: "Aviso de Privacidad",
            nameKey: 'none',
        },
    ];

    return <Sign

        template={agreement?.metadata.template || { version: '', type: '' }}
        srcDoc={agreement?.metadata.fileUrl || ''}
        breadCrumItems={breadCrumItems}
        title="Aviso de Privacidad"
        description="Es un documento en donde te informamos cómo recopilamos, utilizamos y protegemos tus datos personales en albo. En este documento proporcionamos detalles sobre las prácticas de privacidad y tus derechos con respecto a tus datos."
        signer={{
            name: user?.name || "",
            type: "Representante Legal",
            signed: agreement?.status === "done" ,
            date: agreement?.metadata?.signers?.find((signer: any) => signer?.userUuid === user?.userUuid)?.signDate || ''
        }}

    />
}