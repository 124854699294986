import { BreadcrumItem } from "@albo-ui/core";
import { Sign } from "../sign"
import { useAuth } from "../../../providers/auth-provider";
import { useAgreementsToSign } from "../home/hooks/use-agreements-to-sign";

export const SignGuarantee =()=> {
    const { user } = useAuth();
    const {items} = useAgreementsToSign();
    const agreement = items.find(item => item.url === 'guarantee'); 

    const breadCrumItems: BreadcrumItem[] = [
        {
            name: "Inicio",
            nameKey: 'home',
            to: '/sign',
        },
        {
            name: "Garantía colateral",
            nameKey: 'none',
        },
    ];

return <Sign
        template={agreement?.metadata.template || {version: '', type: ''}  }
        srcDoc={agreement?.metadata.fileUrl || ''}
        breadCrumItems={breadCrumItems}
        title="Garantía colateral"
        description="Es el depósito que el cliente realiza al momento de solicitar tarjetas. Su monto se basa en el número de tarjetas físicas solicitadas, a $58.00 por pieza, esto con la finalidad de garantizar el pago de los costos asociados a las mismas."
        signer={{
            name: user?.name || "",
            type:"Representante Legal",
            signed: agreement?.status === "done" ,
            date: agreement?.metadata?.signers?.find((signer: any) => signer?.userUuid === user?.userUuid)?.signDate || ''
            
        }}
    
    />
}