import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Typography,
  CodeInput,
  Link,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { useEffect, useState } from 'react';
import { useTimers } from '../../providers/use-timer';
import {
  ActionType,
  useTwoFactorAuth,
} from '../../providers/two-factor-auth-provider';

const timerName = 'sign';

export type CodeDialogProps = {
  onClose: () => void;
  open: boolean;
  action: ActionType;
  onVerify?: (token:string) => void;
} & DefaultComponentProps;

export const CodeDialog = ({
  onClose,
  open,
  className,
  style,
  action,
  onVerify
}: CodeDialogProps) => {
  const [code, setCode] = useState('');
  const { addTimer, timers } = useTimers();
  const { validatePhoneCode, api, error, lastCodeChangeDate } =
    useTwoFactorAuth();
  const [showError, setShowError] = useState(false);
  const {
    AUTHENTICATION_REQUIRED,
    ENTER_FOUR_DIGIT_CODE_SENT_TO_REGISTERED_PHONE,
    CANCEL,
    RESEND_CODE_AVAILABE_IN,
    RESEND_SMS_CODE,
    SIGN_CONTRACT,
  } = useTranslator();

  const onCodeInput = (value: string) => {
    setCode(value);
  };

  const resendCode = async () => {
    await api.callService('require-auth-sms-code', {
      action: action,
    });
    var endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + 2);
    addTimer(timerName, endDate);
  };

  const onVerifyCode = async () => {
    const result= await validatePhoneCode(code, action);
    if(result.eventName==="PhoneCodeValidated"){
      onVerify?.(result?.data?.token)
    }
    setShowError(true);
  };

  const onCloseDialog = () => {
    setShowError(false);
    onClose();
  };

  useEffect(() => {
    if (error === 'USER_NOT_ALLOWED') {
      var endDate = lastCodeChangeDate
        ? new Date(lastCodeChangeDate)
        : new Date();
      endDate.setMinutes(endDate.getMinutes() + 2);
      addTimer(timerName, endDate);
    }
  }, [error, addTimer, lastCodeChangeDate]);

  return (
    <Dialog
      className={className}
      style={style}
      onClose={onCloseDialog}
      open={open}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {AUTHENTICATION_REQUIRED}
      </DialogTitle>
      <DialogContent>
        <FlexGrid container direction='column' alignItems='center' spacing={4}>
          <FlexGrid item>
            <Typography variant='paragraph'>
              {ENTER_FOUR_DIGIT_CODE_SENT_TO_REGISTERED_PHONE}
            </Typography>
          </FlexGrid>

          <FlexGrid
            item
            container
            fullWidth
            direction='column'
            spacing={3}
            alignItems='center'
          >
            <FlexGrid item>
              <CodeInput
                onInput={onCodeInput}
                size={4}
                error={error === 'INVALID_PHONE_CODE' && showError}
              />
            </FlexGrid>
            <FlexGrid item>
              {timerName in timers ? (
                <Typography variant='paragraph' color='sunset' bold>
                  {RESEND_CODE_AVAILABE_IN}: {timers[timerName].timeLeft.value}
                </Typography>
              ) : (
                <Link onClick={resendCode} bold>
                  {RESEND_SMS_CODE}
                </Link>
              )}
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </DialogContent>
      <DialogActions>
        <FlexGrid container fullHeight fullWidth justify='space-between'>
          <FlexGrid item>
            <Button
              onClick={onCloseDialog}
              size='normal'
              buttonType='secondary'
            >
              {CANCEL}
            </Button>
          </FlexGrid>
          <FlexGrid item>
            <Button onClick={onVerifyCode} size='normal'>
              {SIGN_CONTRACT}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
