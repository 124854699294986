import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  align-items: center;
`;

export const SignContainer = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 7px #edf0f4;
`;
