import { useForm } from '@albo-ui/core';
import { useAuth } from '../providers/auth-provider';
import { useQuery } from '../hooks/user-query';
import { useCallback, useEffect, useState } from 'react';
import { useService } from './use-service';
import { useHistory } from 'react-router';
import { useOnHome } from './use-on-home';
import { useApi } from '../providers/api-provider';
import { useSimpleAlertNotification } from '../providers/simple-alert-provider';
import { useTranslator } from '../providers/translator-provider';

export type OnboardinFormTypes =
  | 'COMPANY_INFO_ACCOUNT'
  | 'LEGAL_REPRESENTATIVE'
  | 'ACCOUNT_CONFIG'
  | 'COMPANY_INFO'
  | 'ADMIN'
  | 'SHAREHOLDER'
  | 'SHAREHOLDER_PM'
  | 'CARD_REQUEST'
  | 'EXTRA_DOCUMENT'
  | 'BENEFICIARY'
  | 'ADMIN_PFAE'
  | 'SOLE_OWNER';

export type QuickWinOnboardinFormTypes =
  | 'QUICK_WIN_LEGAL_REPRESENTATIVE'
  | 'QUICK_WIN_COMPANY_INFO_ACCOUNT'
  | 'QUICK_WIN_ACCOUNT_CONFIG'
  | 'QUICK_WIN_COMPANY_INFO'
  | 'QUICK_WIN_ADMIN'
  | 'QUICK_WIN_SHAREHOLDER'
  | 'QUICK_WIN_SHAREHOLDER_PM'
  | 'QUICK_WIN_CARD_REQUEST'
  | 'QUICK_WIN_EXTRA_DOCUMENT'
  | 'QUICK_WIN_BENEFICIARY'
  | 'QUICK_WIN_PERSONAL_INFO'
  | 'QUICK_WIN_REAL_OWNER'
  | 'QUICK_WIN_COMPLEMENTARY_INFORMATION'
  | 'QUICK_WIN_PEP_RELATION'
  | 'QUICK_WIN_COMPANY_ORGANIZATION'
  | 'QUICK_WIN_ADDITIONAL_INFORMATION'
  | 'QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER';

export type UseOnboardingFormParams = {
  onboardingFormType: OnboardinFormTypes | QuickWinOnboardinFormTypes;
  singleton?: boolean;
  onboardingFormRef?: string;
  defaultParams: any;
  loadTransform?: (data: any) => any;
  nextSectionUrl?: string;
};

export const useOnboardingForm = (params: UseOnboardingFormParams) => {
  const { loadTransform, nextSectionUrl } = params;
  const history = useHistory();
  const [{ movingForward, saving }, setOnboardingFormState] = useState({
    saving: false,
    movingForward: false,
  });
  const formProps = useForm(params.defaultParams);
  const {
    hasErrors,
    hasNonEmptyErrors,
    displayErrors,
    setData: setFormData,
  } = formProps;
  const { leadData, reloadProfile, reloadingProfile, user } = useAuth();
  const onboardingFormsRef = leadData.onboardingForms;
  const [startValidations, setStartValidations] = useState(false);
  const [api] = useApi();
  const {show:showAlertSnackbar} = useSimpleAlertNotification()

  const onboardingFormRef = params.singleton
    ? onboardingFormsRef.find(
      ({ formType }: any) => formType === params.onboardingFormType
    )
    : onboardingFormsRef.find(
      ({ uuid }: any) => params.onboardingFormRef === uuid
    );

  const [queryFormData, loadingForm, reloadForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: [onboardingFormRef?.uuid],
      },
      options: { manualLoad: !onboardingFormRef },
    },
    [onboardingFormRef?.uuid]
  );

  const queryOnboardingForm = queryFormData?.onboardingForms[0];

  const [eventName, , savingForm, save] = useService(
    {
      name: 'save-onboarding-form',
      params: {
        uuid: queryOnboardingForm?.uuid,
        formType: params.onboardingFormType,
        formData: formProps.data,
        user: user?.userUuid
      },
    },
    [formProps.data, params.onboardingFormType, queryOnboardingForm?.uuid]
  );

  const {
    OPS_ANALYST_UPDATE_SUCCESS
  } = useTranslator();

  useOnHome(save);

  const moveForward = useCallback(() => {
    if (hasErrors) {
      displayErrors();
    } else {
      setOnboardingFormState((current) => {
        return ({
          ...current,
          movingForward: true,
          saving: true
        })
      });
      save().then(() => {
        reloadForm();
        reloadProfile();
        setOnboardingFormState((current) => ({
          ...current,
          saving: false,
          movingForward: false,
        }));
        if(leadData?.interactionStatus === 'pending-user-approval'){
          history.push("/onboarding/home/menu");
        }
        
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save, displayErrors, hasErrors]);

  const saveFormOps = useCallback(() => {
    if (!movingForward && !saving && !loadingForm) {
      if (!hasNonEmptyErrors) {
        setOnboardingFormState((current) => ({ ...current, saving: true }));
        save().then(() => {
          reloadForm();
          reloadProfile();
          showAlertSnackbar(OPS_ANALYST_UPDATE_SUCCESS, 'success')
          setOnboardingFormState((current) => ({
            ...current,
            saving: false,
            movingForward: false,
          }));
          history.push("/onboarding/home/menu");
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movingForward, saving, loadingForm, hasNonEmptyErrors, save]);

  const onStartValidations = useCallback(async (data) => {
    setOnboardingFormState((current) => ({
      ...current
    }));
    const result = await api.callService('save-onboarding-form', {
      formData: data,
      formType: 'QUICK_WIN_LEGAL_REPRESENTATIVE'
    });
    return result.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveForm = useCallback(() => {
    if (!movingForward && !saving && !loadingForm) {
      if (!hasNonEmptyErrors) {
        setOnboardingFormState((current) => ({ ...current, saving: true }));
        save();
      }
    }
  }, [movingForward, saving, loadingForm, hasNonEmptyErrors, save]);

  useEffect(() => {
    if (!savingForm && !reloadingProfile && (movingForward || saving)) {
      if (nextSectionUrl && movingForward) {
        reloadProfile();
        history.push(nextSectionUrl);
      } else {
        //TODO: Este reload se ejecuta antes de que se termine de guardar el fomulario. Creo que hay que cambiar estas 3 funciones para que esperen al promise
        reloadForm();
        reloadProfile();
        setOnboardingFormState((current) => ({
          ...current,
          saving: false,
          movingForward: false,
        }));
      }
    }
  }, [
    savingForm,
    movingForward,
    saving,
    reloadingProfile,
    reloadForm,
    nextSectionUrl,
    reloadProfile,
    history,
  ]);

  useEffect(() => {
    if (queryOnboardingForm) {
      setFormData((current: any) => ({
        ...current,
        ...(loadTransform
          ? loadTransform(queryOnboardingForm.formData)
          : queryOnboardingForm.formData),
      }));
    }
  }, [queryOnboardingForm, setFormData, loadTransform]);

  return {
    loadingForm,
    saveForm,
    moveForward,
    saveFormOps,
    onStartValidations,
    startValidations,
    setStartValidations,
    saving,
    movingForward,
    eventName,
    form: formProps,
  };
};
