import { Button, Card, DefaultComponentProps, FlexGrid, Typography } from "@albo-ui/core";

;

export type SignCardProps = {
    name: string;
    type?: string;
    buttonText: string;
    description?: string;
    width?: string;
    height?: string;
    fullWidth?: boolean;
    fullHeight?: boolean;
    disabled?: boolean;
    signDate?: string;
    isSigned?: boolean;
    onClick?: () => void;
} & DefaultComponentProps;

export const SignCard = ({
    buttonText,
    description,
    name,
    type,
    style,
    className,
    fullHeight,
    fullWidth,
    height,
    width,
    disabled,
    isSigned,
    signDate,
    onClick,
}: SignCardProps) => {
    return (
        <Card
            className={className}
            style={style}
            fullHeight={fullHeight}
            fullWidth={fullWidth}
            height={height}
            width={width}
            padding={2}
            alignItems='center'
            justifyContent='center'
        >
            <FlexGrid
                container
                fullWidth
                spacing={2}
                direction='column'
                style={{ textAlign: 'center' }}
            >
                <FlexGrid item>
                    <Typography variant='smallTitle' bold>
                        {name}
                    </Typography>
                </FlexGrid>
                <FlexGrid item>
                    <Typography variant='small' >
                        {type}
                    </Typography>
                </FlexGrid>
                <FlexGrid
                    container
                    direction='column'
                    spacing={1}
                    fullWidth
                    style={{ textAlign: 'center', position: 'relative' }}
                >
                    <FlexGrid item fullWidth>
                        {!isSigned ? <Button onClick={onClick} disabled={disabled}  >
                            {buttonText}
                        </Button>
                            : <Typography variant='small' color='sunset'>
                                {signDate}
                            </Typography>
                        }
                    </FlexGrid>

                    <Typography variant='small' color='sunset'>
                        {description}
                    </Typography>
                </FlexGrid>
            </FlexGrid>
        </Card>
    );
};
