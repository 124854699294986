import { BreadcrumItem } from "@albo-ui/core";
import { Sign } from "../sign"
import { useAuth } from "../../../providers/auth-provider";
import { useAgreementsToSign } from "../home/hooks/use-agreements-to-sign";

export const SignContract =()=> {

    const { user } = useAuth();
    const {items} = useAgreementsToSign();
    const agreement = items.find(item => item.url === 'contract'); 
    

    const breadCrumItems: BreadcrumItem[] = [
        {
            name: "Inicio",
            nameKey: 'home',
            to: '/sign',
        },
        {
            name: "Contrato",
            nameKey: 'none',
        },
    ];

return <Sign
        template={agreement?.metadata.template || {version: '', type: ''}}
        srcDoc={agreement?.metadata.fileUrl || ''}
        breadCrumItems={breadCrumItems}
        title="Carátula, contrato y anexos"
        description="A continuación, encontrarás tu contrato y sus anexos. Te sugerimos revisarlos detenidamente antes de proceder con la firma y aceptación."
        signer={{
            name: user?.name || "",
            type: "Representante Legal",
            signed: agreement?.status === "done" ,
            date: agreement?.metadata?.signers?.find((signer: any) => signer?.userUuid === user?.userUuid)?.signDate || '',
        }}
    
    />
}