
import { Card, Description, Typography, useThemeProvider } from '@albo-ui/core';
import { FaInfoCircle } from 'react-icons/fa';
export const Info = ({description}:{description:string}) => {
    const { theme } = useThemeProvider();

    return (
        <Card
        fullWidth
        alignItems='center'
        style={{
          borderColor: "rgba(180, 232, 254)",
          backgroundColor: "rgba(180, 232, 254, 0.3)",
          borderRadius: '16px',
          padding: `0 ${theme.spacesPx[3]}`,
          minHeight: '70px',
          height: 'auto',
        }}
      >
      <Description
          titleColor='focus'
          icon={<FaInfoCircle color={theme.colors.focus} size='17px' />}
        >
          <Typography variant='paragraph'>
         {description}
          </Typography>
        
        </Description>
        </Card>
    )
}