import { Breadcrum, BreadcrumItem, Card, FlexGrid, Typography,  useThemeProvider } from "@albo-ui/core"
import { OnBoardingWizardStepper } from "../../../components-quick-win/on-boarding-wizard-stepper"
import { useHistory } from "react-router";
import { ContractForm } from "../contract-form";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { useAgreementsToSign } from "../home/hooks/use-agreements-to-sign";

export const Sign = (
    {
        breadCrumItems,
        title,
        description,
        signer,
        template,
        srcDoc
    }: {
        breadCrumItems: BreadcrumItem[],
        title: string,
        description: string,
        signer: {
            name: string,
            signed: boolean,
            date?: string | Date | undefined,
            type: string
        },
        template: {
            version: string;
            type: string;
        },
        srcDoc: string
    }
) => {
    const history = useHistory();
    const { theme } = useThemeProvider();
    const isMobile = useIsMobile()
    const {steps} = useAgreementsToSign();
    const sectionName = {
        'contract': "Carátula, contrato y anexos",
        'guarantee': "Garantía colateral",
        'notice-privacy': "Aviso de privacidad",
        default: '',
    };

    const sectionNumber = {
        'contract': 0,
        'guarantee': 1,
        'notice-privacy': 2,
        default: 0,
    };
    


    const currentPath = `${history.location.pathname.split('/').pop()}`;
    const onHomeClick = () => {
        history.goBack();
    }
    

    return (
        <FlexGrid container fullWidth fullHeight


        >
            <FlexGrid item
                style={{ padding: `${theme.spacesPx[4]} ${theme.spacesPx[2]}` }}
            >
                <OnBoardingWizardStepper
                    onHomeClick={onHomeClick}
                    items={steps}
                    sectionActive={sectionNumber[currentPath as keyof typeof sectionName]}
                    sectionsDone={
                        [false, false, false]
                    }
                />
            </FlexGrid>
            <FlexGrid
                item
                container
                fullHeight
                fullWidth

                style={{
                    position: 'relative',
                    overflow: "hidden",
                    overflowY: 'auto',
                    padding: 0,
                }}
            >
                <Card
                    fullWidth
                    fullHeight
                    alignItems='flex-start'
                    justifyContent='center'
                    style={{
                        borderColor: theme.colors.manatee,
                        borderRadius: 0,
                        overflowY: 'auto',
                        borderRight: 'none',
                        borderBottom: 'none',
                    }}
                >
                    <FlexGrid
                        container
                        fullHeight
                        fullWidth
                        direction='column'
                        spacing={2}
                        justify="flex-start"
                        alignItems='flex-start'
                    >
                        <FlexGrid item wrap>
                            <Breadcrum
                                primaryColor={
                                    'primary'
                                }
                                secondaryColor={
                                    'neutral'
                                }
                                style={{ alignItems: 'flex-start' }}
                                items={breadCrumItems}
                            />
                        </FlexGrid>
                        <FlexGrid
                            item
                            container

                            direction="column"
                            spacing={1}
                            fullHeight
                            fullWidth
                            justify="flex-start"
                            style={{
                                paddingBottom: theme.spacesPx[4],
                                paddingTop: isMobile ? theme.spacesPx[5] : 0
                            }
                            }
                        >
                            <FlexGrid item>
                                <Typography variant='h2'>
                                    {title}
                                </Typography>
                            </FlexGrid>
                            <FlexGrid item>
                                <Typography variant='paragraph'>
                                    {description}
                                </Typography>
                            </FlexGrid>
                            <ContractForm srcDoc={srcDoc} name={signer.name} signerType={signer.type} signed={{
                                name: signer.name,
                                statusText: signer.date ? `Firmado el ${new Date(signer.date).toLocaleString()}` : 'Pendiente de firma',
                                status: signer.signed ? 'done' : 'pending',
                            }} template={template}/>
                        </FlexGrid>
                    </FlexGrid>
                </Card>
            </FlexGrid>
        </FlexGrid>
    )
}