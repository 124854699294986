import React from "react";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = React.useState(false);
  React.useEffect(() => {
    const firsMatch = window.matchMedia(query);
    function handleChange(e:any) {
      const matchQueryList = window.matchMedia(query);
      setMatches(matchQueryList.matches);
    }
    
    setMatches(firsMatch.matches);
    window.addEventListener('resize', handleChange);
    return () => {
      window.removeEventListener("resize", handleChange);
    };
  }, [query]);
  
  return matches;
};
