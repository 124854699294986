import { BrowserRouter } from 'react-router-dom';
import { AlboUiProvider } from '@albo-ui/core';
import { MainRouter } from './router';
import { AuthProvider } from './providers/auth-provider';
import './App.css';
import { TranslatorProvider } from './providers/translator-provider';
import { ApiProvider } from './providers/api-provider';
import { SignUpProvider } from './providers/sign-up-provider';
import { SubmitMoreInfoProvider } from './providers/submit-more-info';
import { SignUpFromMoreInfoProvider } from './providers/sign-up-from-more-info';
import { PasswordRecoveryProvider } from './providers/password-recovery-provider';
import { TimersProvider } from './providers/use-timer';
import { OnHomeCBSProvider } from './providers/on-home-cbs-provider';
import { TwoFactorAuthProvider } from './providers/two-factor-auth-provider';
import { TrackerProvider } from './providers/tracker-provider';
import {
  SimpleNotificationProvider,
  SimpleNotificationProviderSnackBar,
} from './providers/simple-notification-provider';
import {
  CookieUsePolicyNotificationProvider,
  CookieUsePolicyNotificationProviderSnackBar,
} from './providers/cookie-use-policy-notification-provider';
import { FirebaseProvider } from './providers/firebase-provider';
import { DialogMultiSessionProvider, MultiSessionProvider } from './providers/multisession-dialog-provider';
import { ExternalAuthDataProvider, ExternalAuthErrorsProvider } from './providers/external-auth-provider';
import { FormsProvider } from './providers/onboarding-form-provider';
import { UnaffiliatedProvider } from './providers/unaffiliated-auth-provider';


function App() {
  return (
    <AlboUiProvider>
      <OnHomeCBSProvider>
        <ApiProvider>
          <BrowserRouter>
            <TrackerProvider>
              <TranslatorProvider>
                <ExternalAuthDataProvider>
                  <TwoFactorAuthProvider>
                    <AuthProvider>
                      <UnaffiliatedProvider>
                      <ExternalAuthErrorsProvider>
                        <SignUpProvider>
                          <SignUpFromMoreInfoProvider>
                            <SubmitMoreInfoProvider>
                              <PasswordRecoveryProvider>
                                <TimersProvider>
                                  <SimpleNotificationProvider>
                                    <SimpleNotificationProviderSnackBar>
                                      <FormsProvider>
                                        <CookieUsePolicyNotificationProvider>
                                          <CookieUsePolicyNotificationProviderSnackBar>
                                            <FirebaseProvider>
                                              <MultiSessionProvider>
                                                <DialogMultiSessionProvider>
                                                  <MainRouter />
                                                </DialogMultiSessionProvider>
                                              </MultiSessionProvider>
                                            </FirebaseProvider>
                                          </CookieUsePolicyNotificationProviderSnackBar>
                                        </CookieUsePolicyNotificationProvider>
                                      </FormsProvider>
                                    </SimpleNotificationProviderSnackBar>
                                  </SimpleNotificationProvider>
                                </TimersProvider>
                              </PasswordRecoveryProvider>
                            </SubmitMoreInfoProvider>
                          </SignUpFromMoreInfoProvider>
                        </SignUpProvider>
                      </ExternalAuthErrorsProvider>
                      </UnaffiliatedProvider>
                    </AuthProvider>
                  </TwoFactorAuthProvider>
                </ExternalAuthDataProvider>
              </TranslatorProvider>
            </TrackerProvider>
          </BrowserRouter>
        </ApiProvider>
      </OnHomeCBSProvider>
    </AlboUiProvider>
  );
}

export default App;