import { ReactNode, useCallback } from 'react';
import { TopBar, DashboardContainer, useThemeProvider, FlexGrid, Card } from '@albo-ui/core';
import { useAuth } from '../../../providers/auth-provider';
import { LeftSide } from '../../onboarding/layout/left-side';
import { RightSide } from '../../onboarding/layout/right-side';
import GeolocationProvider from '../../../providers/geolocation-provider';
import { SimpleAlertProvider, SimpleAlertProviderSnackBar } from '../../../providers/simple-alert-provider';
import { SideNav } from '../../../components-quick-win/side-nav';
import { useIsMobile } from '../../../hooks/use-is-mobile';

export type LayoutProps = {
    children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
    const { theme } = useThemeProvider();
    const { logout } = useAuth();

    const onLogout = useCallback(() => {
        logout();
    }, [logout]);

    const statusDefault: any = {
        'info': 0,
        'revision': 1,
        'pending-user-approval': 1,
        'request-extrainfo': 1,
        'signing': 2,
        'creating-account': 3,
        'done': 4,
    };
    const getActiveStep = () => {
        return statusDefault.signing;
    };

    const active = getActiveStep();
    //TODO: Add the user name

    const isMobile = useIsMobile();

    return (
        <>
            <TopBar
                leftSide={<LeftSide />}
                rightSide={<RightSide name={""} onLogout={onLogout} />}
                style={{ paddingRight: theme.spacesPx[5] }}
            />
            <DashboardContainer>
                <GeolocationProvider>

                    <FlexGrid container fullWidth fullHeight>

                        <>
                            <SimpleAlertProvider>
                                <SimpleAlertProviderSnackBar>
                                    {!isMobile && <FlexGrid item>
                                        <SideNav
                                            active={active}
                                            status={"pending"}
                                        />
                                    </FlexGrid>}

                                    <Card
                                        fullWidth
                                        fullHeight
                                        style={{
                                            borderColor: theme.colors.manatee,
                                            borderRight: 'none',
                                            borderBottom: 'none',
                                            backgroundColor: 'white',
                                            padding: 0,
                                            overflow:"hidden", 
                                            overflowY: 'auto',
                                            width: isMobile?"100vw":"100%"
                                        }}
                                    >
                                        {children}
                                    </Card>




                                </SimpleAlertProviderSnackBar>
                            </SimpleAlertProvider>
                        </>

                    </FlexGrid>
                </GeolocationProvider>

            </DashboardContainer>
        </>
    );
};
